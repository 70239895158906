import { connect } from 'react-redux';
import Select from './Select';

const mapStateToProps = () => ({
  elemType: 'model',
});

export default connect(
  mapStateToProps,
)(Select);
