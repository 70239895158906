import {
  faCircle, faFileAlt, faMapMarkerAlt, faSlash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LayerSwitcher from 'ol-layerswitcher/dist/ol-layerswitcher';
import Feature from 'ol/Feature';
import Overlay from 'ol/Overlay';
import { getCenter } from 'ol/extent';
import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect, batch } from 'react-redux';
import { addToPopupList, removeFromPopupList } from '../actions';
import '../App.css';
import {
  mapType, ModelInfoType, nonSpatialEntsType, popupInfoType, spatialEntsType, popupListType,
} from '../types/index';
import duplicatePopupExists from '../utils/duplicatePopupExists';
import formatID from '../utils/formatID';
import getEntityFeature from '../utils/getEntityFeature';
import getFeatureLayer from '../utils/getFeatureLayer';
import getFeatureStateAsColour from '../utils/getFeatureStateAsColour';
import PopupViewSwitch from './PopupViewSwitch';


class SingleFeaturePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: true,
    };
    const { feature } = this.props;
    this.properties = feature.getProperties();
    this.featureName = feature.get('Name');
    // If dependencies don't exist in feat, make empty list
    this.dependencies = feature.getProperties().Dependencies || [];
    this.attributes = this.getMatchingAtts();
    this.layerGroup = feature.get('LayerGroup');
    this.layerName = feature.get('Layer');
    this.selfId = formatID(`single-${this.layerGroup}-${this.featureName}`);
    this.iconId = formatID(`${this.featureName}-toggle-visibility`);
    this.getMatchingAtts = this.getMatchingAtts.bind(this);
    this.getEntityIcon = this.getEntityIcon.bind(this);
    this.getDepIcon = this.getDepIcon.bind(this);
    this.addListeners = this.addListeners.bind(this);
    this.addPopupInfo = this.addPopupInfo.bind(this);
    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.popupMouseOver = this.popupMouseOver.bind(this);
    this.popupMouseOut = this.popupMouseOut.bind(this);
    this.popupClick = this.popupClick.bind(this);
    this.checkVisibileAfterToggle = this.checkVisibileAfterToggle.bind(this);
    this.dependencyMouseClick = this.dependencyMouseClick.bind(this);
    this.dependencyMouseOver = this.dependencyMouseOver.bind(this);
    this.dependencyMouseOut = this.dependencyMouseOut.bind(this);
    this.listeningDeps = [];
  }

  // LIFECYCLE METHODS
  componentDidMount() {
    this._ismounted = true;
    const {
      popupId, popupCoords, map, spatialEnts,
    } = this.props;

    const elementId = popupId;
    const containerId = `shell-${popupId}`;
    const popupPosition = [];
    const overlay = new Overlay({ element: document.getElementById(containerId), id: elementId });

    const [popupX, popupY] = popupCoords;
    popupPosition[0] = popupX;
    popupPosition[1] = popupY;

    if (popupX <= 50 || popupY <= 170) {
      // This ensures the popup never displays off of the map
      const viewExtent = map.getView().calculateExtent();
      const centerPos = map.getPixelFromCoordinate(getCenter(viewExtent));
      const [defaultX, defaultY] = centerPos;

      if (popupX <= 50) {
        popupPosition[0] = defaultX;
      }
      if (popupY <= 170) {
        popupPosition[1] = defaultY;
      }
    }

    map.addOverlay(overlay);
    overlay.setPosition(map.getCoordinateFromPixel(popupPosition));


    // Create hover listener for own popup
    const selfContainer = document.getElementById(this.selfId);

    if (selfContainer) {
      // Register click listeners to this popup.
      selfContainer.addEventListener('mouseover', this.popupMouseOver);
      selfContainer.addEventListener('click', this.popupClick);
      selfContainer.addEventListener('mouseout', this.popupMouseOut);
    }

    // Create click listener for icon if spatial entity
    if (spatialEnts[this.featureName] || this.layerGroup === 'Spatial Supporting Data') {
      const iconContainer = document.getElementById(this.iconId);
      if (iconContainer) {
        // If clicked, toggle visibility of layer
        iconContainer.addEventListener('click', this.toggleVisibility);
      }
    }
    this.addListeners();
  }

  componentWillUnmount() {
    this._ismounted = false;
    const selfContainer = document.getElementById(this.selfId);
    const iconContainer = document.getElementById(this.iconId);
    if (selfContainer) {
      // Removing all event listeners when component is removed
      selfContainer.removeEventListener('mouseover', this.popupMouseOver);
      selfContainer.removeEventListener('click', this.popupClick);
      selfContainer.removeEventListener('mouseout', this.popupMouseOut);
    }
    if (iconContainer) {
      // remove event listener from the icon container
      iconContainer.removeEventListener('click', this.toggleVisibility);
    }
    this.listeningDeps.forEach((dep) => {
      dep.removeEventListener('click', this.dependencyMouseClick);
      dep.removeEventListener('mouseover', this.dependencyMouseOver);
      dep.removeEventListener('mouseout', this.dependencyMouseOut);
    });
  }

  /*
    This method looks at the attributes the user specified and returns
    A list of all the ones that the feature contains
   */
  getMatchingAtts() {
    const { ModelInfo } = this.props;
    let attributes = 'all';
    if (ModelInfo) {
      attributes = ModelInfo.attributes;
    }
    const matchingAtts = [];
    const excludedProperties = ['Dependencies', 'ImmediateEffects', 'EntityState',
      'geometry', 'InitialState', 'Name', 'LayerGroup', 'ChangeTime'];
    if (attributes === 'all') {
      Object.keys(this.properties).forEach((key) => {
        if (!(excludedProperties.includes(key))) {
          matchingAtts.push(key);
        }
      });
    } else {
      attributes.forEach((attribute) => {
        if (this.properties[attribute] !== undefined) {
          matchingAtts.push(attribute);
        }
      });
    }
    return matchingAtts;
  }

  /*
    This method returns the icon for each entity (Spatial or
    non-spatial)
  */
  getEntityIcon() {
    const { feature, map, time } = this.props;
    const color = getFeatureStateAsColour(feature, time);
    if (this.layerGroup.startsWith('Spatial')) {
      // spatial feature (entity, initial state, or supporting data)
      const layer = getFeatureLayer(map, feature);
      return layer !== -1 ? (
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement="bottom"
          overlay={(
            <Tooltip id="hoverHelp" left="0" top="unset">
              {
            layer.getVisible() ? 'Hide layer' : 'Show layer'
          }
            </Tooltip>
   )}
        >
          <span className="fa-layers fa-fw" id={formatID(`${this.featureName}-toggle-visibility`)} data-testid={`${this.featureName}-toggle`} style={{ cursor: 'pointer' }}>
            <FontAwesomeIcon data-testid={`${this.featureName}icon`} color={color} size="2x" icon={faMapMarkerAlt} />
            { !layer.getVisible() && (
            <FontAwesomeIcon
              id={formatID(`${this.featureName}-slash`)}
              data-testid={`${this.featureName}slash`}
              color="#000000"
              size="2x"
              icon={faSlash}
              transform="shrink-2 left-4"
            />
            )}
          </span>
        </OverlayTrigger>
      ) : (
      // included only for logical completeness, should never have a feature
      // not belonging to a layer in the map
        <FontAwesomeIcon data-testid={`${this.featureName}icon`} color={color} size="2x" icon={faMapMarkerAlt} />
      );
    }
    if (this.layerGroup.startsWith('Non Spatial')) {
      // non-spatial entity
      return (<FontAwesomeIcon data-testid={`${this.featureName}icon`} color={color} size="2x" icon={faFileAlt} />);
    }
    // default (for non-entities/non-features)
    return (<FontAwesomeIcon data-testid={`${this.featureName}icon`} color={color} icon={faCircle} />);
  }

  getDepIcon(depName) {
    const { spatialEnts, nonSpatialEnts, time } = this.props;
    const depFeature = getEntityFeature(depName, spatialEnts, nonSpatialEnts);
    const color = getFeatureStateAsColour(depFeature, time);
    const depLayerGroup = depFeature.get('LayerGroup');
    if (depLayerGroup.startsWith('Spatial')) {
      // spatial entity listed as a dependency in the popup
      return (<FontAwesomeIcon data-testid={`${depName}icon`} color={color} size="2x" icon={faMapMarkerAlt} />);
    }
    if (depLayerGroup.startsWith('Non Spatial')) {
      // non-spatial entity
      return (<FontAwesomeIcon data-testid={`${depName}icon`} color={color} size="2x" icon={faFileAlt} />);
    }
    // default (for non-entities/non-features)
    return (<FontAwesomeIcon data-testid={`${depName}icon`} color={color} icon={faCircle} />);
  }

  popupMouseOver() {
    const {
      featureIsHighlighted, clearInteractions, highlightFeature, feature, map,
    } = this.props;
    const featLayer = getFeatureLayer(map, feature);
    let isVisible = false;
    if (featLayer !== -1) {
      isVisible = featLayer.getVisible();
    }

    // If the feature isn't highlighted, when we take our mouse off the popup
    // we want the highlight to go away

    if (!featureIsHighlighted(feature)) {
      this.setState({ focused: false });
    }
    if (isVisible) {
      clearInteractions();
      highlightFeature(feature);
    }
  }

  popupClick() {
    const {
      clearInteractions, highlightFeature, feature, map,
    } = this.props;
    const featLayer = getFeatureLayer(map, feature);
    let isVisible = false;
    if (featLayer !== -1) {
      isVisible = featLayer.getVisible();
    }

    if (isVisible) {
      clearInteractions();
      highlightFeature(feature);
    }
    // We want to set this flag so onmouseout doesnt remove highlight if we click
    if (this._ismounted) {
      this.setState({ focused: true });
    }
  }

  popupMouseOut() {
    const { focused } = this.state;
    const { removeHighlightFromFeature, feature, map } = this.props;
    const featLayer = getFeatureLayer(map, feature);
    let isVisible = false;
    if (featLayer !== -1) {
      isVisible = featLayer.getVisible();
    }
    if (!focused && isVisible) {
      removeHighlightFromFeature(feature);
    }
  }

  toggleVisibility() {
    const { map, feature, layerSwitcher } = this.props;
    const layer = getFeatureLayer(map, feature);
    if (layer !== -1) {
      // toggle visibility of layer
      layer.setVisible(!layer.getVisible());

      // needed to prevent weird bug where layer does not become visible
      // again (when toggled on) until layerswitcher panel is re-rendered
      if (layerSwitcher) {
        layerSwitcher.renderPanel();
      }
      this.checkVisibileAfterToggle();
    }
  }

  checkVisibileAfterToggle() {
    const {
      map, feature, clearInteractions, highlightFeature,
    } = this.props;
    const isVisible = getFeatureLayer(map, feature).getVisible();

    // If we are toggling to visible, highlight that feature
    if (isVisible) {
      clearInteractions();
      highlightFeature(feature);
    // Else unhighlight it
    } else {
      clearInteractions();
    }
  }

  /*
     This method creates a click listener to each item in dep list
  */
  addListeners() {
    // NOTE: THESE ARE FOR HIGHLIGHTING DEPENDENCIES, SEE componentDidMount
    // FOR HIGHLIGHTING THE FEATURES
    this.dependencies.map((dep) => {
      const featureSpan = document.getElementById(formatID(`dep-${dep}`));
      if (featureSpan) {
        // If clicked, open dependency's popup
        featureSpan.addEventListener('click',
          this.dependencyMouseClick.bind(null, dep));

        // If moused over, highlight feature popup
        featureSpan.addEventListener('mouseover',
          this.dependencyMouseOver.bind(null, dep));

        // If moused out, unhighlight feature popup
        featureSpan.addEventListener('mouseout',
          this.dependencyMouseOut.bind(null, dep));

        // This is so we can remove Event Listeners on unmount
        this.listeningDeps.push(featureSpan);
      }
      return false;
    });
  }

  dependencyMouseClick(dep) {
    const {
      map, spatialEnts, nonSpatialEnts, popupId, dispatch, popupInfo, popupCoords,
    } = this.props;
    const feature = getEntityFeature(dep, spatialEnts, nonSpatialEnts, map);

    // If the feature exists, create click and hover listeners on the dep bullet
    if (feature) {
      const featureId = formatID(`single-${feature.get('LayerGroup')}-${dep}`);
      if (!(duplicatePopupExists(feature, map))) {
        const overlay = map.getOverlayById(popupId);
        map.removeOverlay(overlay);

        // Do both things before re-rendering
        batch(() => {
          dispatch(removeFromPopupList(popupInfo));
          dispatch(addToPopupList({ feature: [feature], popupId: featureId, popupCoords }));
        });
      }
    }
  }

  dependencyMouseOver(dep) {
    const {
      spatialEnts, nonSpatialEnts, popupList, map,
    } = this.props;
    const feature = getEntityFeature(dep, spatialEnts, nonSpatialEnts, map);

    // If the feature exists, create click and hover listeners on the dep bullet
    if (feature) {
      const featureId = formatID(`single-${feature.get('LayerGroup')}-${dep}`);
      popupList.forEach((popup) => {
        if (popup.popupId === featureId) {
          const infoContainer = document.getElementById(featureId);
          if (infoContainer) {
            infoContainer.style.border = 'solid 2px blue';
          }
        }
      });
    }
  }

  dependencyMouseOut(dep) {
    const { spatialEnts, nonSpatialEnts, map } = this.props;
    const feature = getEntityFeature(dep, spatialEnts, nonSpatialEnts, map);
    const featureId = formatID(`single-${feature.get('LayerGroup')}-${dep}`);
    const infoContainer = document.getElementById(featureId);
    if (infoContainer) {
      infoContainer.style.border = '';
    }
  }

  /* return a layer select for initial state and entity popups
   (to switch between the two)
  */
  addLayerSwitch() {
    const { popupCoords, popupInfo } = this.props;
    return (
      <PopupViewSwitch
        key={`${this.featureName}-${this.layerGroup}`}
        featureName={this.featureName}
        layerGroup={this.layerGroup}
        popupInfo={popupInfo}
        popupCoords={popupCoords}
      />
    );
  }

  /*
    This method returns a react fragment with all the dependencies and
    attributes formatted for the popup.
  */
  addPopupInfo() {
    return (
      <>
        {this.dependencies.length > 0 && (
        <figure>
          <figcaption className="list-cap">
            Dependencies:
          </figcaption>
          <ul>
            {this.dependencies.map((dep) => (
              <li
                data-testid={dep}
                style={{ listStyle: 'none' }}
                key={dep.replace(/[^0-9a-z]/gi, '')}
                className={dep.replace(/[^0-9a-z]/gi, '')}
              >
                <span className="state-bullet" data-testid="state-bullet">{this.getDepIcon(dep)}</span>
                <span
                  id={formatID(`dep-${dep}`)}
                  style={{ cursor: 'pointer' }}
                  className="popup-text"
                >
                  {dep}
                </span>
              </li>
            ))}
          </ul>
        </figure>
        )}
        {this.attributes.length > 0 && (
        <figure>
          <figcaption className="list-cap">
            Attributes:
          </figcaption>
          <ul>
            {this.attributes.map((attribute) => (
              this.properties[attribute] !== undefined && (
              <li
                data-testid={attribute}
                style={{ listStyle: 'none' }}
                key={attribute}
              >
                <span className="state-bullet"><FontAwesomeIcon icon={faCircle} /></span>
                <span className="popup-text attribute">{`${attribute}: ${this.properties[attribute]}`}</span>
              </li>
              )
            ))}
          </ul>
        </figure>
        )}
      </>
    );
  }


  render() {
    const { popupId } = this.props;
    return (
      <>

        <div
          className="ol-popup info-container"
          id={popupId}
        >
          <div className=" single-popup-header">

            <h5 style={{ textAlign: 'center' }}>
              <span
                style={{ listStyle: 'none' }}
                className="title-bullet"
                data-testid={this.featureName}
              >
                {this.getEntityIcon(this.featureName, this.layerGroup)}
              </span>
              {this.featureName}
            </h5>
            <h6 style={{ textAlign: 'center' }}>{`(${this.layerGroup})`}</h6>
          </div>
          {(this.layerGroup.includes('Initial State') || this.layerGroup.includes('Entity')) && this.addLayerSwitch()}
          {!this.layerGroup.includes('Initial State') && this.addPopupInfo()}
        </div>
      </>
    );
  }
}

SingleFeaturePopup.propTypes = {
  dispatch: PropTypes.elementType.isRequired,
  ModelInfo: ModelInfoType,
  feature: PropTypes.instanceOf(Feature).isRequired,
  highlightFeature: PropTypes.elementType.isRequired,
  removeHighlightFromFeature: PropTypes.elementType.isRequired,
  featureIsHighlighted: PropTypes.elementType.isRequired,
  clearInteractions: PropTypes.elementType.isRequired,
  map: mapType,
  nonSpatialEnts: nonSpatialEntsType,
  spatialEnts: spatialEntsType,
  popupCoords: PropTypes.arrayOf(PropTypes.number),
  popupId: PropTypes.string.isRequired,
  popupInfo: popupInfoType,
  time: PropTypes.number.isRequired,
  layerSwitcher: PropTypes.instanceOf(LayerSwitcher),
  popupList: popupListType,
};

const mapStateToProps = (state) => ({
  map: state.map,
  time: state.sliderTime,
  nonSpatialEnts: state.nonSpatialEnts,
  spatialEnts: state.spatialEnts,
  popupList: state.popupList,
  interactions: state.interactions,
  layerSwitcher: state.toggleableControls.find((ctrl) => ctrl instanceof LayerSwitcher),
});

export default connect(mapStateToProps)(SingleFeaturePopup);
