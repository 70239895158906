import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export const OpenModelButton = ({ selectedModelId, onClick }) => (
  <Button
    onClick={onClick}
    data-test-id="open"
    disabled={selectedModelId === -1}
  >
    Open
  </Button>
);

OpenModelButton.propTypes = {
  selectedModelId: PropTypes.number.isRequired,
  onClick: PropTypes.elementType,
};

const mapStateToProps = (state) => ({
  selectedModelId: state.selectedModelId,
});

export default connect(mapStateToProps)(OpenModelButton);
