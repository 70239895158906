const popupList = (state = [], action) => {
  switch (action.type) {
    case 'ADD_TO_POPUP_LIST':
      return [
        ...state,
        action.popupItem,
      ];
    case 'REMOVE_FROM_POPUP_LIST':
      return state.filter((popupItem) => popupItem !== action.popupItem);
    case 'CLEAR_POPUP_LIST':
      return [];
    default:
      return state;
  }
};

export default popupList;
