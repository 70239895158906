import { Map } from 'ol';

const map = (state = new Map({}), action) => {
  switch (action.type) {
    case 'ADD_MAP':
      return action.map;
    default:
      return state;
  }
};

export default map;
