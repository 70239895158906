const toggleableControls = (state = [], action) => {
  switch (action.type) {
    case 'ADD_CONTROLS':
      return action.toggleableControls;
    default:
      return state;
  }
};

export default toggleableControls;
