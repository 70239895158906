// To change color scheme in all of app, you only need to change the first
// two values

// **NOTE: if you dont use rgb, the custom opacity functions will break**


export const operationalColor = 'rgb(0,190,0)';
export const offlineColor = 'rgb(255,0,0)';

// **Try these colors? They look alot less harsh, in my opinion.
// export const operationalColor = 'rgb(52, 203, 52)';
// export const offlineColor = 'rgb(219, 36, 48)';

export function operationalColorCustomOpacity(opacity) {
  // This splits rgb string to extract values
  const colorArray = operationalColor.substring(4, operationalColor.length - 1)
    .replace(/ /g, '') // get rid of spaces
    .split(','); // split on commas
  return (`rgba(${colorArray[0]},${colorArray[1]},${colorArray[2]},${opacity})`);
}

export function offlineColorCustomOpacity(opacity) {
  const colorArray = offlineColor.substring(4, offlineColor.length - 1)
    .replace(/ /g, '')
    .split(',');
  return (`rgba(${colorArray[0]},${colorArray[1]},${colorArray[2]},${opacity})`);
}
