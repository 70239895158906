import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Map, View } from 'ol';
import ScaleLine from 'ol/control/ScaleLine';
import LayerSwitcher from 'ol-layerswitcher/dist/ol-layerswitcher';
import 'ol-layerswitcher/src/ol-layerswitcher.css';
import Control from 'ol/control/Control';
import { pointerMove } from 'ol/events/condition';
import Select from 'ol/interaction/Select';
import LayerGroup from 'ol/layer/Group';
import TileLayer from 'ol/layer/Tile';
import 'ol/ol.css';
import { fromLonLat } from 'ol/proj';
import OSM from 'ol/source/OSM';
import PropTypes from 'prop-types';
import React from 'react';
import {
  caseInfoType, legendInfoType, lyrType, mapType,
  ModelInfoType, nonSpatialEntsType, popupListType, spatialEntsType, interactionsType,
} from '../types';
import duplicatePopupExists from '../utils/duplicatePopupExists';
import formatID from '../utils/formatID';
import getEntityFeature from '../utils/getEntityFeature';
import getFeaturePixelPosition from '../utils/getFeaturePixelPosition';
import { createVectorLayer, updateMapExtent } from '../utils/mapHelpers';
import InteractivePopupShell from './InteractivePopupShell';
import Legend from './Legend';
import NonSpatialEnsList from './NonSpatialEnsList';
import ZoomToExtent from './ZoomToExtent';


// ****************************CLASS DECLARATION**************************

class ModelMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      multiPopupId: 0,
      preloadPopupsLoaded: false,
      supLayersVisibility: {},
    };
    this.showPopup = this.showPopup.bind(this);
    this.hoverListener = '';
    this.createPopup = this.createPopup.bind(this);
    this.preloadPopups = this.preloadPopups.bind(this);
    this.layerGroupVisListener = this.layerGroupVisListener.bind(this);
    this.mapRef = 'mapContainer';
  }


  // LIFECYCLE METHODS
  componentDidMount() {
    const {
      toggleExpandedTimeline, initStLyrs, supLyrs, entityLyrs, selectedCaseId, addMap,
    } = this.props;
    toggleExpandedTimeline(false);
    const layers = [];
    // create base map layers
    const baseLyrs = [];
    const osmLayer = new TileLayer({
      source: new OSM(),
      title: 'Basic Map',
      type: 'base',
      visible: false,
    });
    baseLyrs.push(osmLayer);

    const grayOsmLayer = new TileLayer({
      source: new OSM(),
      title: 'Grayscale Map',
      type: 'base',
      visible: true,
    });
    // apply grayscale to each pixel in canvas
    // (based on Xavier's code at: https://medium.com/@xavierpenya/openlayers-3-osm-map-in-grayscale-5ced3a3ed942)
    // If ever upgrading to Ol6 again change to postrender
    grayOsmLayer.on('postcompose', (event) => {
      const { context } = event;
      const { canvas } = context;
      const { width } = canvas;
      const { height } = canvas;
      const imageData = context.getImageData(0, 0, width, height);
      const { data } = imageData;
      for (let i = 0; i < data.length; i += 4) {
        const r = data[i];
        const g = data[i + 1];
        const b = data[i + 2];
        // CIE luminance for the RGB
        let v = 0.2126 * r + 0.7152 * g + 0.0722 * b;
        // Show white color instead of black color while loading new tiles:
        if (v === 0.0) v = 255.0;
        data[i + 0] = v; // Red
        data[i + 1] = v; // Green
        data[i + 2] = v; // Blue
        data[i + 3] = 255; // Alpha
      }
      context.putImageData(imageData, 0, 0);
    });
    baseLyrs.push(grayOsmLayer);

    const baseLyrGroup = new LayerGroup({
      title: 'Base Maps',
      openInLayerSwitcher: false,
      layers: baseLyrs,
      fold: 'close',
    });
    layers.push(baseLyrGroup);

    // Layers we will hide during loading
    const hideLyrs = [];

    // create initial state layers
    const localInitStLyrs = [];
    let layerGroup = 'Spatial Initial State';
    initStLyrs.forEach((layer) => {
      const { url, title, visible } = layer;
      // param 4 is so styleFeatures knows that it is an initial state layer
      const initStLyr = createVectorLayer(url, title, layerGroup, true);
      if (!visible) {
        //  keep track of (initially) hidden layers
        hideLyrs.push(initStLyr);
      }
      localInitStLyrs.push(initStLyr);
    });
    const initStLyrGroup = new LayerGroup({
      title: 'Initial State Values (Spatial)',
      openInLayerSwitcher: false,
      layers: localInitStLyrs,
      fold: 'close',
    });
    initStLyrGroup.on('change', (e) => { this.layerGroupVisListener(e); });
    layers.push(initStLyrGroup);

    let supLayersVisibility = {};
    // create supporting data layers
    const localSupLyrs = [];
    layerGroup = 'Spatial Supporting Data';
    if (supLyrs.length > 0) {
      supLyrs.forEach((layer) => {
        const { url, title, visible } = layer;
        const supLyr = createVectorLayer(url, title, layerGroup, false);
        // register callback for layer visibility change
        supLyr.on('change:visible', (e) => {
          // console.log(title, e.target.getVisible())
          this.setState(prevState => ({
            supLayersVisibility: {
              ...prevState.supLayersVisibility, // keep visibility of other supporting layers
              [title]: e.target.getVisible(), // update visibility of this layer
            }
          }));
        })
        // record initial visibility of layer
        supLayersVisibility[title] = visible;
        
        if (!visible) {
          //  keep track of (initially) hidden layers
          hideLyrs.push(supLyr);
        }
        localSupLyrs.push(supLyr);
      });
      const supLyrGroup = new LayerGroup({
        title: 'Supporting Data (Spatial)',
        openInLayerSwitcher: false,
        layers: localSupLyrs,
        fold: 'close',
      });
      supLyrGroup.on('change', (e) => { this.layerGroupVisListener(e); });
      layers.push(supLyrGroup);
    }

    // create model entity layers
    const localEntityLyrs = [];
    layerGroup = 'Spatial Entity';
    entityLyrs.forEach((layer) => {
      const { url, title, visible } = layer;
      const entLyr = createVectorLayer(url, title, layerGroup, false);
      if (!visible) {
        //  keep track of (initially) hidden layers
        hideLyrs.push(entLyr);
      }
      localEntityLyrs.push(entLyr);
    });

    const allMapLyrs = localSupLyrs.concat(localInitStLyrs, localEntityLyrs);

    const entityLyrGroup = new LayerGroup({
      title: 'Entities (Spatial)',
      openInLayerSwitcher: true,
      layers: localEntityLyrs,
      fold: 'open',
    });
    entityLyrGroup.on('change', (e) => { this.layerGroupVisListener(e); });
    layers.push(entityLyrGroup);

    const bc = [-127.647621, 53.726669]; // longitude first, then latitude
    // transform the coordinates for OSM
    const bcWebMercator = fromLonLat(bc);
    // create map object with above layers
    const localMap = new Map({
      target: this.mapRef,
      layers,
      view: new View({
        center: bcWebMercator,
        zoom: 5,
        maxZoom: 17,
      }),
    });


    // add extra map controls and feature interactions
    this.addControlsAndInteractions(localMap);

    const loadingPage = document.getElementById('load-page');
    if (selectedCaseId === -1) {
      if (loadingPage) {
        loadingPage.style.display = 'none';
      }
    }

    // callback for change event in visible entity layer source (most importantly
    // if it has finished loading)
    // used to zoom map to extent of visible entity layers when sources ready
    let loadedSrcs = 0;
    const srcChanged = (lyr) => () => {
      const src = lyr.getSource();
      if (src.getState() === 'ready') {
        loadedSrcs += 1;
        // If this layer is to be hidden, hide it
        if (hideLyrs.includes(lyr)) {
          lyr.setVisible(false);
        }
      }
      // all the layers are loaded into map
      if (loadedSrcs === allMapLyrs.length) {
        // map has completely rendered
        // update extent to only the visible layers
        updateMapExtent(localMap, false);
        localMap.on('rendercomplete', () => {
          if (loadingPage) {
            // remove loading page
            const { preloadPopupsLoaded } = this.state;
            // Add preloaded popups into map
            if (preloadPopupsLoaded === false) {
              this.preloadPopups(localMap);
            }
            loadingPage.style.display = 'none';
          }
        });
      }
    };

    // set event handler for source of each (initially) visible entity layer

    allMapLyrs.forEach((lyr) => {
      const src = lyr.getSource();
      src.once('change', srcChanged(lyr));
    });


    addMap(localMap);
    const activeInteractionsAtLoad = 10;
    // This is black magic -- the hover feature interaction always sits at array
    // position 9, when the evt target isn't the map it takes away the highlight feature
    // otherwise adds it back if the size of the interactions list is smaller than
    // size with hover added
    const hoverFeature = localMap.getInteractions().array_[9];
    this.hoverListener = (evt) => {
      // Remove hover interaction if not hovering on map (eg on a popup or a dropdown)
      if (evt.target.className !== 'ol-unselectable') {
        if (localMap.interactions.getArray().length >= activeInteractionsAtLoad) {
          localMap.removeInteraction(hoverFeature);
        }

      // If hover removed, add back if target is map
      } else if (localMap.interactions.getArray().length === activeInteractionsAtLoad) {
        localMap.addInteraction(hoverFeature);
      }
    };
    document.addEventListener('mousemove', this.hoverListener, false);
    
    this.setState({ supLayersVisibility: supLayersVisibility });
  }

  componentDidUpdate() {
    const { map } = this.props;
    map.updateSize();
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.hoverListener);
  }

  /** This method adds a listener to each layer group, and when visibility is
    * toggled off, the layer removes the highlighting if exists
    * @listens Layergroup:change
    * @param {Object[]} e The change event
  */
  layerGroupVisListener(e) {
    // For each layer in the changed group
    e.target.getLayers().forEach((layer) => {
      const { interactions, setInteractions } = this.props;

      // For testing purposes
      if (interactions) {
        // This is all the interactions
        const interArray = interactions.getFeatures().getArray();

        // Get array of layer name for each interaction (= to highlight of feature)
        const interArrayLayers = interactions.getFeatures().getArray().map((item) => item.get('Layer'));

        // Get another array that is just the layergroup of each interaction
        const interArrayLayerGroups = interactions.getFeatures().getArray().map((item) => item.get('LayerGroup'));

        // Check each layer visibility in group - if false, if highlighted, remove highlighting
        if (layer.getVisible() === false) {
          const layerTitle = layer.get('title');
          const layerGroup = layer.get('LayerGroup');
          const indexOfInter = interArrayLayers.indexOf(layerTitle);
          const indexOfInterGroup = interArrayLayerGroups.indexOf(layerGroup);

          // If the layerTitle is found in the interactions, and the layerGroup is the same
          // remove
          if (indexOfInter !== -1
            && interArrayLayerGroups[indexOfInter] === interArrayLayerGroups[indexOfInterGroup]) {
            const feature = interArray[indexOfInter];
            interArray.forEach((item) => {
              if (item === feature) {
                interactions.getFeatures().remove(feature);
              }
            });
            setInteractions(interactions);
          }
        }
      }
    });
  }

  /** Called by the click event listeners to add a new popup to the redux store 'popupList'.
    * This is based on the features on the map at the click/hover position.
    * @param {Array} featureList    The list of features at the click event's position
    * @param {Object[]} evt           The click event object
  */
  createPopup(featureList, evt) {
    const { map, addToPopupList } = this.props;
    const { multiPopupId } = this.state;
    let coords = [500, 500];
    const mapContainer = document.getElementById('model-map');
    const mapY = mapContainer.getBoundingClientRect().top;
    // clientX not supported in all browsers, pointerEvent works as substitute
    if (evt.clientX) {
      coords = [Math.floor(evt.clientX), Math.floor(evt.clientY) - mapY];
    } else {
      coords = [Math.floor(evt.pointerEvent.clientX), Math.floor(evt.pointerEvent.clientY) - mapY];
    }
    let popupId;
    if (featureList.length === 1) {
      // Dont show the same feature twice for a singleFeature popup
      if (duplicatePopupExists(featureList[0], map)) return;
      popupId = formatID(`single-${featureList[0].get('LayerGroup')}-${featureList[0].get('Name')}`);
    } else {
      popupId = formatID(`multi-${multiPopupId}`);
      this.setState({ multiPopupId: multiPopupId + 1 });
    }
    // Add popup to list
    addToPopupList({ feature: featureList, popupId, popupCoords: coords });
  }

  /** This method is the callback function for a click event on the map.
    * It gets all the features at a click and sends the info to createPopup.
    * @listens event:onclick
    * @param {Object[]} evt    The click event object
  */
  showPopup(evt) {
    const { map } = this.props;
    const featureList = [];
    // Attempt to find a feature in one of the visible vector layers
    map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
      if (layer !== null) {
        // If feature doesn't have a name property, set the name to be its Layer
        if (feature.get('Name') === undefined) {
          feature.set('Name', layer.get('title'));
        }
        featureList.push(feature);
      }
    });
    if (featureList.length > 0) {
      this.createPopup(featureList, evt);
    }
  }

  /*  This method creates all of the controls that are on the map, as well as
      the interactions such as hover and click.
  */
  addControlsAndInteractions(map) {
    const { setInteractions } = this.props;

    // add layer switcher control
    const layerControl = new LayerSwitcher({ activationMode: 'click', collapseLabel: '' });
    const button = layerControl.element.childNodes[0];
    button.style.backgroundImage = 'none';
    button.removeAttribute('title');
    // layerControl.element.style.backgroundImage = 'none';
    const layerSwitcherIcon = document.getElementById('layer-switcher-icon');
    button.appendChild(layerSwitcherIcon);
    button.addEventListener('click', () => {
      button.appendChild(layerSwitcherIcon);
    });
    map.addControl(layerControl);

    // add Non-Spatial items
    const myElement = document.getElementById('non-spatial-list');
    const nonSpatialPopup = new Control({ element: myElement });
    map.addControl(nonSpatialPopup);

    const mapExtentDiv = document.getElementById('zoom-to-extent-div');
    const mapExtentButton = new Control({ element: mapExtentDiv });
    map.addControl(mapExtentButton);

    // add scale
    const scale = new ScaleLine();
    map.addControl(scale);

    // add highlight feature on mouse-hover
    const hoverFeature = new Select({
      condition: pointerMove,
    });
    map.addInteraction(hoverFeature);

    // add highlight feature on "singleclick"
    const selectFeature = new Select({
    });
    setInteractions(selectFeature);
    map.addInteraction(selectFeature);

    // add popups
    // Add an event handler for the map "singleclick" event
    map.on('singleclick', this.showPopup);

    return map;
  }

  preloadPopups(map) {
    const {
      caseInfo, popupList, addToPopupList, spatialEnts, nonSpatialEnts,
    } = this.props;
    const keyToLayersDict = {
      'Entities (Spatial)': 'Spatial Entity',
      'Initial State Values (Spatial)': 'Spatial Initial State',
      'Supporting Data (Spatial)': 'Spatial Supporting Data',
      'Entities (Non Spatial)': 'Non Spatial Entity',
      'Initial State Values (Non Spatial)': 'Non Spatial Initial State',
    };
    const windowWidth = window.innerWidth;
    const addPopup = (feat) => {
      if (feat && !(duplicatePopupExists(feat, map))) {
        const featureId = formatID(`single-${feat.get('LayerGroup')}-${feat.get('Name')}`);
        const featureCoords = getFeaturePixelPosition(
          feat, map, popupList.length, windowWidth,
        );
        addToPopupList({
          feature: [feat],
          popupId: featureId,
          popupCoords: featureCoords,
        });
      }
    };
    if (caseInfo) {
      if ('preload_popups' in caseInfo && caseInfo.preload_popups !== undefined) {
        if (caseInfo.preload_popups === 'all') {
          // load popups for all features
          
          // add popups for spatial features
          map.getLayers().getArray().forEach((lyrGroup) => {
            if (lyrGroup.get('title') !== 'Base Maps') {
              lyrGroup.getLayers().forEach((layer) => {
                layer.getSource().getFeatures().forEach(addPopup);                
              });
            }
          });
          
          // add popups non-spatial features
          for (let [entName, ent] of Object.entries(nonSpatialEnts)) {
            let layerGroup = 'Non Spatial Entity';
            let popupFeature = getEntityFeature(entName, [], nonSpatialEnts, layerGroup);
            // make sure properties needed for ID are included
            popupFeature.set('Name', entName)
            popupFeature.set('LayerGroup', layerGroup)
            addPopup(popupFeature);
            if ('InitialState' in ent) {
              // add popup for initial state
              layerGroup = 'Non Spatial Initial State';
              popupFeature = getEntityFeature(entName, [], nonSpatialEnts, layerGroup);
              // make sure properties needed for ID are included
              popupFeature.set('Name', entName)
              popupFeature.set('LayerGroup', layerGroup)
              addPopup(popupFeature);
            }
          }
        } else {
          // load popups for selected features
          const preloadedPopups = caseInfo.preload_popups;
          Object.keys(preloadedPopups).forEach((key) => {
            const layerGroup = keyToLayersDict[key];
            if (preloadedPopups[key] === 'all') {
              // load popups for all features in this layer group
              if (layerGroup.startsWith('Non Spatial')) {
                for (let [entName, ent] of Object.entries(nonSpatialEnts)) {                  
                  if (layerGroup === 'Non Spatial Initial State' && 'InitialState' in ent) {
                    // add popup for initial state
                    const popupFeature = getEntityFeature(entName, [], nonSpatialEnts, layerGroup);
                    // make sure properties needed for ID are included
                    popupFeature.set('Name', entName)
                    popupFeature.set('LayerGroup', layerGroup)
                    addPopup(popupFeature);
                  } else {
                    const popupFeature = getEntityFeature(entName, [], nonSpatialEnts, layerGroup);
                    // make sure properties needed for ID are included
                    popupFeature.set('Name', entName)
                    popupFeature.set('LayerGroup', layerGroup)
                    addPopup(popupFeature);
                  }
                }
              } else {
                // spatial layer
                map.getLayers().getArray().some((lyrGroup) => {
                  if (lyrGroup.get('title') === layerGroup) {
                    lyrGroup.getLayers().forEach((layer) => {
                      layer.getFeatures().forEach(addPopup);                
                    });
                    return true;
                  }
                  return false;
                });
              }
            } else {
              preloadedPopups[key].forEach((entity) => {
                const popupFeature = getEntityFeature(entity, spatialEnts, nonSpatialEnts,
                                                      layerGroup, map);
                // make sure properties needed for ID are included (particularly for non-spatial ens)
                popupFeature.set('Name', entity)
                popupFeature.set('LayerGroup', layerGroup)
                addPopup(popupFeature);
              });
            }
          });
        }
      }
    }
    this.setState({ preloadPopupsLoaded: true });
  }


  render() {
    const {
      popupList, nonSpatialEnts, ModelInfo, legendInfo,
    } = this.props;
    const {
      supLayersVisibility
    } = this.state;

    return (
      <>
        <div id="load-page" className="loading-screen loading-screen-map">
          <div className="load-spinner" />
          <div className="loading-screen loading-screen-top-nav" />
          <FontAwesomeIcon
            id="layer-switcher-icon"
            key="MapMarkerAltIcon"
            color="rgb(114,191,203)"
            size="lg"
            icon={faMapMarkerAlt}
          />
        </div>

        <div ref={(c) => { this.mapRef = c; }} id="model-map" className="model-map">
          <NonSpatialEnsList nonSpatialEnts={nonSpatialEnts} />
          <ZoomToExtent />
          <Legend legendInfo={legendInfo} supLayersVisibility={supLayersVisibility}/>
        </div>


        {popupList.map((popup) => (
          <InteractivePopupShell
            key={popup.popupId}
            popupInfo={popup}
            ModelInfo={ModelInfo}
          />
        ))}

      </>
    );
  }
}

ModelMap.propTypes = {
  legendInfo: legendInfoType,
  interactions: interactionsType,
  ModelInfo: ModelInfoType,
  caseInfo: caseInfoType,
  addMap: PropTypes.elementType.isRequired,
  addToPopupList: PropTypes.elementType.isRequired,
  entityLyrs: lyrType,
  initStLyrs: lyrType,
  supLyrs: lyrType,
  map: mapType,
  popupList: popupListType,
  selectedCaseId: PropTypes.number,
  setInteractions: PropTypes.elementType.isRequired,
  nonSpatialEnts: nonSpatialEntsType,
  spatialEnts: spatialEntsType,
  toggleExpandedTimeline: PropTypes.elementType,
};

ModelMap.defaultProps = {
  nonSpatialEnts: {},
};

export default ModelMap;
