export const modelIds = (state = [], action) => {
  switch (action.type) {
    case 'ADD_MODEL':
      return [
        ...state,
        action.id,
      ];

    default:
      return state;
  }
};

export const scenIds = (state = [], action) => {
  switch (action.type) {
    case 'ADD_SCEN':
      return [
        ...state,
        action.id,
      ];
    default:
      return state;
  }
};

export const caseIds = (state = [], action) => {
  switch (action.type) {
    case 'ADD_CASE':
      return [
        ...state,
        action.id,
      ];
    default:
      return state;
  }
};
