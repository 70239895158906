import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setNonSpatialEnts, setSpatialEnts } from '../actions';
import '../App.css';
import Map from '../containers/Map';
import ModelForm from '../containers/ModelForm';
import { casesByIdType, modelsByIdType } from '../types';
import modelPathNamesMatch from '../utils/modelPathNamesMatch';
import ExpandedTimeline from './ExpandedTimeline';
import Header from './Header';
import model_select from '../select_models_by_path.json';
import bundleLoader from './bundle-loader';

// helper function to get keys to lookup non-spatial/spatial ents and legend info for selected case
function getCaseInfoKeys(_case) {
  const nonSpatialKey = `./${_case.ent_files_dir}/Non Spatial Entities.json`.replace(/\\/g, '/');
  const spatialKey = `./${_case.ent_files_dir}/Spatial Entities.json`.replace(/\\/g, '/');
  const legendKey = `./${_case.ent_files_dir}/map_app_legend.json`.replace(/\\/g, '/');
  return {nonSpatialKey, spatialKey, legendKey};
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      mobile: window.innerWidth < 1400,
    };
    // Dynamically import all model_info.json files from the folder ModelInfoList
    let allModels;
    this.nonSpatialEntInfoDict = {};
    this.spatialEntInfoDict = {};
    this.legendInfoDict = {};
    try {
      let {models, nonSpatialEntInfoDict, spatialEntInfoDict, legendInfoDict} = bundleLoader.importFiles();
      allModels = models;
      this.nonSpatialEntInfoDict = nonSpatialEntInfoDict;
      this.spatialEntInfoDict = spatialEntInfoDict;
      this.legendInfoDict = legendInfoDict;
    } catch (err) {
      // In testing, this function doesn't work so we just mock an empty model
      // console.log(err);
      allModels = [];
    }
    this.resizeWindow = this.resizeWindow.bind(this);
    this.addModels(allModels);
  }
  
  resizeWindow() {
    this.setState({
      mobile: window.innerWidth < 1400,
    });
  }

  // LIFECYCLE METHODS
  componentDidMount() {
    /* Event listener to update state to be used as check for
       component layout when window is horizontally shrunk
       below a certain threshold
    */
    window.addEventListener('resize', this.resizeWindow, false);

    // To use for testing -- makes it so you don't have to pick the
    // case/scenario/model every time
    // dispatch(selectModel(1));
    // dispatch(selectScenario(1));
    // dispatch(selectCase(1));
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeWindow, false);
  }

  componentDidUpdate(prevProps) {
    const { selectedCaseId, casesById, dispatch } = this.props;
    if (selectedCaseId !== prevProps.selectedCaseId && selectedCaseId !== -1) {
      
      const _case = casesById[selectedCaseId];
      // keys to look up loaded contents of files
      const { nonSpatialKey, spatialKey } = getCaseInfoKeys(_case);
      
      if (nonSpatialKey in this.nonSpatialEntInfoDict) {
        // add non-spatial ents info to redux store
        dispatch(setNonSpatialEnts(this.nonSpatialEntInfoDict[nonSpatialKey]));
      } else {
        // no non-spatial ents, add empty object to store
        dispatch(setNonSpatialEnts({}));
      }
      if (spatialKey in this.spatialEntInfoDict) {
        // add spatial ents info to redux store
        dispatch(setSpatialEnts(this.spatialEntInfoDict[spatialKey]));
      } else {
        // non spatial ents, add empty object to store (shouldn't occur)
        dispatch(setSpatialEnts({}));
      }
    }
  }

  /**
    * Returns layers as specified for each layergroup in model_info.json.
    * @param {string} keyValue   The value in model_info for the layer group.
    * @param {string} files       The key specifying the file list in model_info.
    * @param {string} fileDir   The key specifying the directory for the files.
    *
    * @return {Object} Returns a sorted list of layers for the specified layer group
  */
  getLayers(keyValue, files, fileDir) {
    const { casesById, selectedCaseId } = this.props;
    const layers = [];
    if (selectedCaseId !== -1) {
      const currentCase = casesById[selectedCaseId];
      const caseDir = currentCase[fileDir];
      const caseFiles = currentCase[files];
      let visibleKey;
      let keyType;
      let allTrue = false;
      if ('visible_layers' in currentCase && currentCase.visible_layers) {
        // If visible_layers: 'true' in model_info
        if (typeof currentCase.visible_layers === 'string' && currentCase.visible_layers.toLowerCase() === 'all') {
          allTrue = true;
        } else if (keyValue in currentCase.visible_layers) {
          visibleKey = currentCase.visible_layers[keyValue];
          keyType = typeof visibleKey;
        }
      }
      caseFiles.forEach((file) => {
        let layerName = file.replace(/.json/g, '');
        // If initial state files we want to remove init from front of name
        if (files === 'init_st_files') {
          layerName = file.replace(/ Init/g, '').replace(/.json/g, '');
        }
        let visible = false;
        if (allTrue) {
          visible = true;
        } else if (visibleKey) {
          // If list of keys, check if current layer in visible keys
          if (keyType === 'object') {
            visible = !!visibleKey.includes(layerName);
          // If key says all, make all visible
          } else if (keyType === 'string' && visibleKey.toLowerCase() === 'all') {
            visible = true;
          }
        }
        
        let url = `${caseDir.replace(/\\/g, '/')}/${file}`;
        if (process.env.NODE_ENV === "development") {
          url = `${window.location.origin}/${url}`;
        }
        layers.push({
          url: url,
          title: layerName,
          visible,
        });
      });
    }
    return layers.sort((a, b) => (b.title.toLowerCase() < a.title.toLowerCase() ? -1 : 1));
  }

  /**
    * Adds all of the models in the public folder into the app.
    * This is dependent on the pathname at the end of the url.
    * @param {Array} allModels   This is a list of all the models in the public folder.
  */
  addModels(allModels) {
    const { addModel } = this.props;
    // remove leading and trailing slashes from pathname
    let urlPathName = window.location.pathname.replace(/^\/|\/$/g, '');
    if (process.env.NODE_ENV === "production") {
      if ("use_path" in model_select) {
        // set app to selected path
        urlPathName = model_select["use_path"];
      } else {
        // set app to path where all models available
        urlPathName = 'test'; 
      }      
    }
    
    // add models from public folder
    allModels.forEach((model) => {
      // add model if pathnames match or if app at /test path
      if (urlPathName.toLowerCase() === 'test' || modelPathNamesMatch(urlPathName, model.path_name)) {
        // console.log('match')
        addModel(model);
      }
    });
  }

  render() {
    const {
      selectedCaseId, casesById, modelsById, selectedModelId,
    } = this.props;
    const { mobile, modalShow } = this.state;
    const modalClose = () => this.setState({ modalShow: false });    
    
    let nonSpatialInfo = undefined;
    let spatialInfo = undefined;
    let legendInfo = undefined;
    let allEntities = [];
    let time = 0;
    let disableTimeline = false;
    if (selectedCaseId !== -1) {
      const _case = casesById[selectedCaseId];
      // look up loaded contents of files for selected case
      const { nonSpatialKey, spatialKey, legendKey } = getCaseInfoKeys(_case);
      nonSpatialInfo = this.nonSpatialEntInfoDict[nonSpatialKey];
      spatialInfo = this.spatialEntInfoDict[spatialKey];
      legendInfo = this.legendInfoDict[legendKey];
      
      time = Math.ceil(_case.max_time);
      if ('disable_timeline' in _case && _case.disable_timeline) {
        disableTimeline = true;
      }
    }
    if (spatialInfo && nonSpatialInfo) {
      allEntities = Object.keys(spatialInfo).concat(Object.keys(nonSpatialInfo));
    }

    return (
      <>
        <Header mobile={mobile} disableTimeline={disableTimeline} />
        <ModelForm show={modalShow} onHide={modalClose} />
        <ExpandedTimeline
          key={selectedCaseId}
          maxTime={time}
          allEntities={allEntities}
          nonSpatialEnts={nonSpatialInfo}
          spatialEnts={spatialInfo}
        />
        <div className="page-content" id="map-container">
          <Map
            key={selectedCaseId}
            supLyrs={this.getLayers('Supporting Data (Spatial)', 'sup_files', 'sup_files_dir')}
            entityLyrs={this.getLayers('Entities (Spatial)', 'ent_files', 'ent_files_dir')}
            initStLyrs={this.getLayers('Initial State Values (Spatial)', 'init_st_files', 'init_st_files_dir')}
            nonSpatialEnts={nonSpatialInfo}
            spatialEnts={spatialInfo || {}}
            legendInfo={legendInfo}
            ModelInfo={modelsById[selectedModelId]}
            caseInfo={casesById[selectedCaseId]}
          />
        </div>

      </>
    );
  }
}

App.propTypes = {
  addModel: PropTypes.elementType.isRequired,
  casesById: casesByIdType,
  dispatch: PropTypes.elementType,
  modelsById: modelsByIdType,
  selectedCaseId: PropTypes.number,
  selectedModelId: PropTypes.number,
};

const mapStateToProps = (state) => ({
  selectedCaseId: state.selectedCaseId,
  selectedModelId: state.selectedModelId,
  casesById: state.casesById,
  modelsById: state.modelsById,
});

export default connect(mapStateToProps)(App);
