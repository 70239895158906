import { connect } from 'react-redux';
import Attribution from 'ol/control/Attribution';
import {
  addControls, addMap, setInteractions, addToPopupList, toggleExpandedTimeline,
} from '../actions';
import ModelMap from '../components/ModelMap';

const getControls = (map) => {
  const controls = [];
  map.getControls().forEach((ctrl) => {
    if (!(ctrl instanceof Attribution)) {
      controls.push(ctrl);
    }
  });

  return controls;
};

const mapStateToProps = (state) => ({
  map: state.map,
  time: state.sliderTime,
  selectedCaseId: state.selectedCaseId,
  interactions: state.interactions,
  popupList: state.popupList,
  timelineExpanded: state.timelineExpanded,
});

const mapDispatchToProps = (dispatch) => ({
  addMap(map) {
    dispatch(addControls(getControls(map)));
    dispatch(addMap(map));
  },
  setInteractions(interactions) {
    dispatch(setInteractions(interactions));
  },
  addToPopupList(popupList) {
    dispatch(addToPopupList(popupList));
  },
  toggleExpandedTimeline(timelineExpanded) {
    dispatch(toggleExpandedTimeline(timelineExpanded));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModelMap);
