import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Button } from 'react-bootstrap';
import { faFileAlt, faMapMarkerAlt, faQuestion } from '@fortawesome/free-solid-svg-icons';

import '../App.css';
import { operationalColor, offlineColor } from '../utils/colors';

class HelpModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  // LIFECYCLE METHODS
  componentDidUpdate(prevProps) {
    const { selectedCaseId } = this.props;
    if (selectedCaseId !== prevProps.selectedCaseId) {
      this.hideModal();
    }
  }

  /*  This method toggles the modal when the help button is clicked.
  */
  toggleModal() {
    const { open } = this.state;
    if (open === false) {
      this.setState({ open: true });
    } else {
      this.hideModal();
    }
  }

  /*  This method helps toggleModal, as well as onHide for the modal.
  */
  hideModal() {
    this.setState({ open: false });
  }


  render() {
    const { open } = this.state;
    return (
      <>
        <Button variant="outline-dark" onClick={this.toggleModal} data-testid="help-modal-toggle">
          <FontAwesomeIcon icon={faQuestion} />
        </Button>
        <Modal
          show={open}
          onHide={this.hideModal}
          data-testid="help-modal"
          id="help-modal"
          size="lg"
          aria-labelledby="help-modal-title"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="help-modal-title" style={{ textAlign: 'center' }}>
              About the app
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: '400px', overflowY: 'scroll' }}>
            <b>Steps to Get Started</b>
            <br />
            <ol>
              <li>
                Select a
                <i> model</i>
                {' '}
                (A model describes entities of interest, such as infrastructure,
                and how they depend on other entities)
              </li>
              <li>
                Select a
                <i> scenario</i>
                {' '}
                (A scenario is a specific hazard)
              </li>
              <li>
                Select a
                <i> case</i>
                {' '}
                (A case is a possible outcome of that hazard occurring)
              </li>
            </ol>
            <b>Spatial and Non-Spatial Data</b>
            <br />
            Each model contains some data that can be shown on the map because it has location
            information, and some data that cannot be shown on the map because it does not
            have location information.
            <br />
            <br />
            Data that is spatial is indicated by
            {' '}
            <FontAwesomeIcon color="rgba(0,0,0,1)" icon={faMapMarkerAlt} />
            .
            Data that is not is indicated by
            {' '}
            <FontAwesomeIcon color="rgba(0,0,0,1)" icon={faFileAlt} />
            .
            <br />
            <br />
            Spatial and non-spatial model entities can have two
            {' '}
            <i>states</i>
            :
            (1)
            {' '}
            <span style={{ color: operationalColor }}>operational</span>
            {' '}
            and
            (2)
            {' '}
            <span style={{ color: offlineColor }}>non-operational</span>
            .
            <br />
            <br />
            <FontAwesomeIcon color="rgba(0,0,0,1)" icon={faFileAlt} />
            {' '}
            <b>Types of Non-Spatial Data</b>
            <br />
            <ol>
              <li>
                <i>Non-Spatial Entities: </i>
                These are entities included in the
                {' '}
                <i>model</i>
                {' '}
                that do not have spatial information.
                They can be in an
                {' '}
                <i>operational</i>
                {' '}
                or
                {' '}
                <i>non-operational</i>
                {' '}
                state.
              </li>
              <li>
                <i>Non-Spatial Initial State:</i>
                {' '}
                This data shows the expected
                {' '}
                <i> state</i>
                {' '}
                of non-spatial model entities due to the
                direct effects of the
                {' '}
                <i> scenario</i>
                {' '}
                hazard
              </li>
            </ol>
            <br />
            <FontAwesomeIcon color="rgba(0,0,0,1)" icon={faMapMarkerAlt} />
            {' '}
            <b>Types of Spatial Data</b>
            <br />
            <ol>
              <li>
                <i>Spatial Entities: </i>
                These are entities included in the
                {' '}
                <i>model</i>
                {' '}
                that have spatial information.
                They can be in an
                {' '}
                <i>operational</i>
                {' '}
                or
                {' '}
                <i>non-operational</i>
                {' '}
                state.
              </li>
              <li>
                <i>Spatial Supporting Data: </i>
                {' '}
                Additional spatial data that helps explain the
                {' '}
                <i>scenario</i>
                {' '}
                or
                {' '}
                <i>case</i>
                (such as a map of expected ground motions associated with an earthquake)
              </li>
              <li>
                <i>Spatial Initial State: </i>
                {' '}
                This data shows the expected
                <i> state</i>
                {' '}
                of spatial model
                entities due to the direct effects of the
                <i> scenario</i>
                {' '}
                hazard
              </li>
            </ol>


          </Modal.Body>
          <Modal.Footer>

          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

HelpModal.propTypes = {
  selectedCaseId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  selectedCaseId: state.selectedCaseId,
});

export default connect(mapStateToProps)(HelpModal);
