import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import '../App.css';
import { mapType } from '../types/index';

class ToggleMapControls extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showControls: true,
    };
    this.ToggleMapControls = this.ToggleMapControls.bind(this);
  }

  /* This method hides/shows map controls (not including OSM credit or legend)
  */
  ToggleMapControls() {
    let show;
    const { showControls } = this.state;
    const { toggleableControls, map } = this.props;
    if (showControls) {
      // hide controls
      show = false;
      toggleableControls.forEach((ctrl) => map.removeControl(ctrl));
    } else {
      // show controls
      show = true;
      toggleableControls.forEach((ctrl) => map.addControl(ctrl));
    }
    this.setState({ showControls: show });
  }

  render() {
    const { showControls } = this.state;
    return (
      <Button id="toggle-controls-button-id" variant="outline-dark" onClick={this.ToggleMapControls} className="toggle-controls-button">
        {`${showControls ? 'Hide' : 'Show'} map controls`}
      </Button>
    );
  }
}

ToggleMapControls.propTypes = {
  map: mapType,
  toggleableControls: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = (state) => ({
  map: state.map,
  toggleableControls: state.toggleableControls,
});

export default connect(mapStateToProps)(ToggleMapControls);
