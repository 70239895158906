/* eslint camelcase: "off" */
// For now, this rule is turned off as the models
// from the json data come in as camel case -- might be
// worth discussing if we want to change our model
// to camelCase for within the web app?

/* eslint no-plusplus: "off" */
// Turning this rule off too, as changing the iteration on the
// id doesn't seem like it makes sense, but leaving rule on
// for other files.

let nextModelId = 0;
let nextScenId = 0;
let nextCaseId = 0;
export const addModel = ({
  name, description, attributes, scenarios, time_unit, subtype,
}) => ({
  type: 'ADD_MODEL',
  id: nextModelId++,
  name,
  description,
  attributes,
  time_unit,
  scenarios,
  subtype, // should be either 'pre-inst' or 'alt'
});

export const addScenario = ({ name, description, cases }) => ({
  type: 'ADD_SCEN',
  id: nextScenId++,
  name,
  description,
  cases,
});

export const addCase = ({
  name, description, ent_files_dir, ent_files,
  sup_files, sup_files_dir, init_st_files, init_st_files_dir, max_time,
  visible_layers, disable_timeline, timeline_preload_ents, preload_popups,
}) => ({
  type: 'ADD_CASE',
  id: nextCaseId++,
  name,
  description,
  ent_files_dir,
  ent_files,
  sup_files_dir,
  sup_files,
  init_st_files_dir,
  init_st_files,
  max_time,
  visible_layers,
  disable_timeline,
  timeline_preload_ents,
  preload_popups,
});

export const addFile = ({ model_id, name }) => ({
  type: 'ADD_FILE',
  model_id, // model must be added before file
  name,
});

export const addMap = (map) => ({
  type: 'ADD_MAP',
  map,
});

export const addControls = (toggleableControls) => ({
  type: 'ADD_CONTROLS',
  toggleableControls,
});

export const selectModel = (id) => ({
  type: 'SELECT_MODEL',
  id,
});

export const selectScenario = (id) => ({
  type: 'SELECT_SCEN',
  id,
});

export const selectCase = (id) => ({
  type: 'SELECT_CASE',
  id,
});

export const showOperational = (status) => ({
  type: 'SHOW_OPERATIONAL',
  status,
});

export const showOffline = (status) => ({
  type: 'SHOW_OFFLINE',
  status,
});

export const setSliderTime = (time) => ({
  type: 'SET_SLIDER_TIME',
  time,
});

export const setHighestZIndex = (index) => ({
  type: 'SET_HIGHEST_ZINDEX',
  index,
});

export const setInteractions = (interactions) => ({
  type: 'SET_INTERACTIONS',
  interactions,
});

export const addToPopupList = (popupItem) => ({
  type: 'ADD_TO_POPUP_LIST',
  popupItem,
});

export const removeFromPopupList = (popupItem) => ({
  type: 'REMOVE_FROM_POPUP_LIST',
  popupItem,
});

export const clearPopupList = (popupItem) => ({
  type: 'CLEAR_POPUP_LIST',
});

export const addToGraphList = (graphItem) => ({
  type: 'ADD_TO_GRAPH_LIST',
  graphItem,
});

export const removeFromGraphList = (graphItem) => ({
  type: 'REMOVE_FROM_GRAPH_LIST',
  graphItem,
});

export const reorderGraphList = (graphIndex, direction) => ({
  type: 'REORDER_GRAPH_LIST',
  graphIndex,
  direction,
});

export const clearGraphList = () => ({
  type: 'CLEAR_GRAPH_LIST',
});

export const setNonSpatialEnts = (entList) => ({
  type: 'SET_NON_SPATIAL_ENT_LIST',
  entList,
});

export const setSpatialEnts = (entList) => ({
  type: 'SET_SPATIAL_ENT_LIST',
  entList,
});

export const toggleExpandedTimeline = (toggleValue) => ({
  type: 'TOGGLE_EXPANDED_TIMELINE',
  toggleValue,
});
