import { connect } from 'react-redux';
import Select from './Select';

const mapStateToProps = () => ({
  elemType: 'scenario',
  style: { width: '300px', marginRight: '5px' },
});

export default connect(
  mapStateToProps,
)(Select);
