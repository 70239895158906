import Feature from 'ol/Feature';
import LayerGroup from 'ol/layer/Group';

/*
  Helper method to return the feature object representing a (spatial or
  non-spatial) entity
*/
export default function getEntityFeature(entName, spatialEnts, nonSpatialEnts, lyrGroup = '', map) {
  let featToReturn = '';
  let realFeatFound = false;
  // If the map object isn't passed, then we will just get the feature from the list of entities.
  if (map) {
    const layers = map.getLayers().getArray();
    layers.forEach((layer) => {
      if (layer instanceof LayerGroup && layer.get('title') !== 'Base Maps') {
        const vectorLayers = layer.getProperties().layers.getArray();
        vectorLayers.forEach((vectorLayer) => {
          if (vectorLayer.get('LayerGroup') === lyrGroup) {
            const features = vectorLayer.getSource().getFeatures();
            features.forEach((feature) => {
              if (feature.get('Name') === entName) {
                featToReturn = feature;
                realFeatFound = true;
              }
            });
          }
        });
      }
    });
  }
  /* If we can't find the real feature on the map we will instead make one --
      note, this happens if the layers are hidden, but once they are shown it fixes it
  */
  if (!realFeatFound) {
    if (entName in spatialEnts) {
      // spatial entity
      const feature = new Feature(spatialEnts[entName]);
      // use default layer group if not specified
      const lyrGroupName = lyrGroup.length > 0 ? lyrGroup : 'Spatial Entity';
      feature.set('LayerGroup', lyrGroupName);
      featToReturn = feature;

      // only use feature if layer group is valid
      if (lyrGroupName !== 'Spatial Entity' && lyrGroupName !== 'Spatial Initial State') {
        featToReturn = '';
      }
    } else if (entName in nonSpatialEnts) {
      // non-spatial entity
      const feature = new Feature(nonSpatialEnts[entName]);
      // use default layer group if not specified
      const lyrGroupName = lyrGroup.length > 0 ? lyrGroup : 'Non Spatial Entity';

      feature.set('LayerGroup', lyrGroupName);
      feature.set('Name', entName); // add name property for consistency with spatial entities
      featToReturn = feature;

      // only use feature if layer group is valid
      if (lyrGroupName !== 'Non Spatial Entity' && lyrGroupName !== 'Non Spatial Initial State') {
        featToReturn = '';
      }
    }
  }
  // entity not found
  return featToReturn;
}
