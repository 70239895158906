import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import '../App.css';
import { elementsByIdType } from '../types';
import formatID from '../utils/formatID';


const hoverDescription = (elemDescription) => (
  <Tooltip style={{ zIndex: 130000 }} id="hoverDescription">{elemDescription}</Tooltip>
);

const addBlankItem = (caseType) => {
  let message = caseType;
  if (caseType === 'case') {
    message = 'First select a scenario';
  } else if (caseType === 'scenario') {
    message = 'First select a model';
  } else if (caseType === 'model') {
    message = 'No models in app';
  }
  return (
    <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={hoverDescription('Clear Selection')}>
      <Dropdown.Item key="-1" eventKey="-1" data-testid="-1" disabled="true">{message}</Dropdown.Item>
    </OverlayTrigger>
  );
};
const ElementSelect = ({
  selectedElement, elements, elementsById, onSelect, caseType,
}) => (
  <Dropdown>
    <Dropdown.Toggle variant="outline-dark" id={`dropdown-basic-${caseType}`} className="dropdown-box">
      <span className="dropdown-display">
        {selectedElement === -1 ? `Select a ${caseType}...` : elementsById[selectedElement].name}
      </span>

    </Dropdown.Toggle>
    <Dropdown.Menu id={caseType} className="dropdown-box">
      {elements.length === 0 && addBlankItem(caseType)}
      {elements.map((id) => (
        <OverlayTrigger key={caseType + id} trigger={['hover', 'focus']} placement={window.innerWidth >= 1400 ? 'right' : 'left'} overlay={hoverDescription(elementsById[id].description)}>
          <Dropdown.Item
            key={caseType + id}
            style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}
            id={formatID(`element-${(elementsById[id].name)}`)}
            eventKey={caseType + id}
            data-testid={id}
            onClick={() => onSelect(id)}
          >
            {elementsById[id].name}
          </Dropdown.Item>
        </OverlayTrigger>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);

ElementSelect.propTypes = {
  selectedElement: PropTypes.number.isRequired,
  elements: PropTypes.arrayOf(PropTypes.number).isRequired,
  elementsById: elementsByIdType,
  onSelect: PropTypes.elementType.isRequired,
  caseType: PropTypes.string,
};

export default ElementSelect;
