import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import '../App.css';
import { mapType, popupListType, graphListType } from '../types';


class PreloadedItemList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.listVisibleLayers = this.listVisibleLayers.bind(this);
    this.listPopupPreloads = this.listPopupPreloads.bind(this);
    this.listTimelinePreloads = this.listTimelinePreloads.bind(this);
  }

  // LIFECYCLE METHODS

  /*  This method toggles the modal when the list button is clicked.
  */
  toggleModal() {
    const { open } = this.state;
    if (open === false) {
      this.setState({ open: true });
    } else {
      this.hideModal();
    }
  }

  /*  This method helps toggleModal, as well as onHide for the modal.
  */
  hideModal() {
    this.setState({ open: false });
  }

  listVisibleLayers() {
    const { map } = this.props;
    const visibleLyrJson = {};
    map.getLayers().getArray().forEach((lyrGroup) => {
      if (lyrGroup.get('title') !== 'Base Maps') {
        visibleLyrJson[lyrGroup.get('title')] = [];
        lyrGroup.getLayers().forEach((layer) => {
          if (layer.getVisible()) {
            visibleLyrJson[lyrGroup.get('title')].push((layer.get('title')));
          }
        });
      }
    });
    // Get amounts of layers so that we don't add trailing comma to end of json object
    const lyrGroupLen = Object.keys(visibleLyrJson).length;
    // Sort the layers before displaying them
    Object.keys(visibleLyrJson).forEach((group) => {
      visibleLyrJson[group].sort((a, b) => (b.toLowerCase() > a.toLowerCase() ? -1 : 1));
    });
    return (
      <div>
        {'"visible_layers": {'}
        {
          Object.keys(visibleLyrJson).map((lyr, i) => (
            <pre
              key={`print-vis-layer-list-span-${lyr}`}
              className="preload-list-format"
            >
              {`  "${lyr}": [`}
              {visibleLyrJson[lyr].map((item, j, arr) => (
                <pre
                  key={`print-list-${item}`}
                  className="preload-list-format"
                >
                  {`    "${item}"${arr.length !== j + 1 ? ',' : ''}`}
                </pre>
              ))}
              {`  ]${lyrGroupLen !== i + 1 ? ',' : ''}`}
              <br />
            </pre>
          ))
        }
        {'},'}
      </div>
    );
  }

  listPopupPreloads() {
    const { popupList } = this.props;
    const layersToKeyDict = {
      'Spatial Entity': 'Entities (Spatial)',
      'Spatial Initial State': 'Initial State Values (Spatial)',
      'Spatial Supporting Data': 'Supporting Data (Spatial)',
      'Non Spatial Entity': 'Entities (Non Spatial)',
      'Non Spatial Initial State': 'Initial State Values (Non Spatial)',
    };
    const printPopupList = {
      'Entities (Spatial)': [],
      'Initial State Values (Spatial)': [],
      'Supporting Data (Spatial)': [],
      'Entities (Non Spatial)': [],
      'Initial State Values (Non Spatial)': [],
    };
    const printPopupListLen = Object.keys(printPopupList).length;
    popupList.forEach((popup) => {
      if (popup.feature.length === 1) {
        const feature = popup.feature[0];
        const printLayerGroup = layersToKeyDict[feature.get('LayerGroup')];
        printPopupList[printLayerGroup].push(feature.get('Name'));
      }
    });
    return (
      <div>
        {'"preload_popups": {'}
        {
          Object.keys(printPopupList).map((lyr, i) => (
            <pre
              key={`print-popup-list-span-${lyr}`}
              className="preload-list-format"
            >
              {`  "${lyr}": [`}
              {printPopupList[lyr].map((item, j, arr) => (
                <pre
                  key={`print-popup-list-${item}`}
                  className="preload-list-format"
                >
                  {`    "${item}"${arr.length !== j + 1 ? ',' : ''}`}
                </pre>
              ))}
              {`  ]${printPopupListLen !== i + 1 ? ',' : ''}`}
              <br />
            </pre>
          ))
        }
        {'},'}
      </div>
    );
  }

  listTimelinePreloads() {
    const { graphList } = this.props;
    const graphListLen = graphList.length;
    return (
      <div>
        &quot;timeline_preload_ents&quot;: [

        {
          graphList.map((graphItem, i) => (
            <pre
              key={`timeline-preload-list-span-${graphItem.name}`}
              className="preload-list-format"
            >
              {`  "${graphItem.name}"${graphListLen !== i + 1 ? ',' : ''}`}
            </pre>
          ))
        }
        ],
      </div>
    );
  }


  render() {
    const { open } = this.state;
    return (
      <>
        <Button variant="outline-dark" onClick={this.toggleModal} data-testid="preloaded-item-list-toggle">
          <FontAwesomeIcon icon={faPrint} />
        </Button>
        <Modal
          show={open}
          onHide={this.hideModal}
          data-testid="preloaded-item-list"
          id="preloaded-item-list"
          size="lg"
          aria-labelledby="preloaded-item-list-title"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="preloaded-item-list-title" style={{ textAlign: 'center', userSelect: 'none' }}>
              List of preloaded Items
            </Modal.Title>
          </Modal.Header>
          <Modal.Body data-testid="preloaded-item-list-body" style={{ maxHeight: '400px', overflowY: 'scroll' }}>
            {this.listVisibleLayers()}
            {this.listPopupPreloads()}
            {this.listTimelinePreloads()}
          </Modal.Body>
          <Modal.Footer>

          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

PreloadedItemList.propTypes = {
  map: mapType,
  popupList: popupListType,
  graphList: graphListType,
};

const mapStateToProps = (state) => ({
  map: state.map,
  popupList: state.popupList,
  graphList: state.graphList,
});

export default connect(mapStateToProps)(PreloadedItemList);
