import formatID from './formatID';

// Helper function makes sure a popup doesn't get created twice for
// the same feature
export default function duplicatePopupExists(feat, map) {
  let duplicate = false;
  const overlayList = map.getOverlays().array_;
  overlayList.forEach((listItem) => {
    const overlayId = formatID(`single-${feat.get('LayerGroup')}-${feat.get('Name')}`);
    if (overlayId === listItem.id) {
      duplicate = true;
    }
  });
  return duplicate;
}
