const altModelFile = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_FILE':
      return {
        name: action.name,
        model_id: action.model_id,
      };
    default:
      return state;
  }
};

export default altModelFile;
