import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { addToPopupList, removeFromGraphList, reorderGraphList } from '../actions';
import downArrow from '../images/down_arrow.png';
import upArrow from '../images/up_arrow.png';
import {
  graphListType, mapType, nonSpatialEntsType, popupListType, spatialEntsType,
} from '../types';
import { offlineColor, operationalColor } from '../utils/colors';
import duplicatePopupExists from '../utils/duplicatePopupExists';
import formatID from '../utils/formatID';
import getEntityFeature from '../utils/getEntityFeature';
import getFeaturePixelPosition from '../utils/getFeaturePixelPosition';


class TimelineGraph extends React.Component {
  constructor(props) {
    super(props);
    this.graphMax = 1;
    // Graph variables
    this.rectHeight = 30;
    this.rectSpacing = this.rectHeight + 5;
    this.offlineData = [];
    this.operationalData = [];
    this.names = [];
    this.labelWidth = 250;
    this.listeningLabels = [];

    this.drawChart = this.drawChart.bind(this);
    this.drawGraph = this.drawGraph.bind(this);
    this.drawLabels = this.drawLabels.bind(this);
    this.drawLegend = this.drawLegend.bind(this);
    this.drawArrows = this.drawArrows.bind(this);
    this.windowResizeListener = this.windowResizeListener.bind(this);
    this.removeItemListener = this.removeItemListener.bind(this);
    this.labelMouseClick = this.labelMouseClick.bind(this);
    this.labelMouseOver = this.labelMouseOver.bind(this);
    this.labelMouseOut = this.labelMouseOut.bind(this);
  }

  // LIFECYCLE METHODS
  componentDidMount() {
    this.drawChart();
    this.removeItemListener();
    window.addEventListener('resize', this.windowResizeListener, false);
  }

  // Right now I redraw the chart every time the component updates, as it seemed
  // to be the most straightforward way using d3 - maybe can find a way to only redraw
  // the new item when item is added, the whole graph when item is deleted, and timeline line
  // when time is updated? Seems to run fine the way it is currently
  componentDidUpdate() {
    this.drawChart();
    this.removeItemListener();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResizeListener, false);
    this.listeningLabels.forEach((label) => {
      label.removeEventListener('click', this.labelMouseClick);
      label.removeEventListener('mouseover', this.labelMouseOver);
      label.removeEventListener('mouseout', this.labelMouseOut);
    });
    const yAxis = document.getElementById('y-axis');
    if (yAxis) {
      yAxis.removeEventListener('mousedown', this.mouseDown);
    }
    document.removeEventListener('mouseup', this.mouseUp);
  }

  /*
    Helper method to draw the labels for each item on the graph(Names)
  */
  drawLabels(graphHeight) {
    const {
      graphList,
    } = this.props;

    const labelContainer = document.getElementById('graph-labels');
    const svgLabel = d3.select(labelContainer)
      .append('svg')
      .attr('id', 'labels-svg')
      .attr('width', this.labelWidth)
      .attr('height', graphHeight)
      .attr('padding-right', 20);
    // Create labels for each entity
    svgLabel.selectAll('text.label')
      .data(this.names)
      .enter()
      .append('g')
      .attr('class', 'label-container')
      .append('text')
      .text((d) => (d.length <= this.labelWidth / 7 ? d : d.substr(0, this.labelWidth / 7).concat('...')))
      .attr('font-size', 13)
      .attr('x', 0)
      .attr('width', 60)
      .attr('display', 'block')
      .attr('class', 'label')
      .attr('id', (d) => formatID(`label-${d}`))
      .attr('cursor', 'pointer')
      .attr('y', (d, i) => this.rectSpacing / 2 + (i * this.rectSpacing))
      .append('svg:title')
      .text((d) => d);
    // Create Click listeners for labels
    graphList.forEach((item) => {
      const label = document.getElementById(formatID(`label-${item.name}`));
      label.addEventListener('mouseover', this.labelMouseOver.bind(null, item.name));
      label.addEventListener('mouseout', this.labelMouseOut.bind(null, item.name));
      label.addEventListener('click', this.labelMouseClick.bind(null, item.name));
      this.listeningLabels.push(label);
    });
  }

  /**
    * Event listener for mouseclick on labels in graph
    * opens a new popup if not one already open
    * @param {string} labelName    The name of the label in the graph
    *
  */
  labelMouseClick(labelName) {
    const {
      popupList, map, spatialEnts, nonSpatialEnts, dispatch,
    } = this.props;
    let layerGroup = 'Spatial Entity';
    if (labelName in nonSpatialEnts) {
      layerGroup = 'Non Spatial Entity';
    }
    const feature = getEntityFeature(labelName, spatialEnts, nonSpatialEnts, layerGroup, map);
    const featureId = formatID(`single-${feature.get('LayerGroup')}-${labelName}`);
    if (!(duplicatePopupExists(feature, map))) {
      const featureCoords = getFeaturePixelPosition(
        feature, map, popupList.length, window.innerWidth,
      );
      dispatch(addToPopupList({
        feature: [feature], popupId: featureId, popupCoords: featureCoords,
      }));
    }
  }

  /**
    * Event listener for mouseover on labels in graph
    * highlights corresponding popup of hovered label
    * @param {string} labelName    The name of the label in the graph
    *
  */
  labelMouseOver(labelName) {
    const {
      spatialEnts, nonSpatialEnts, popupList, map,
    } = this.props;
    const feature = getEntityFeature(labelName, spatialEnts, nonSpatialEnts, map);

    // If the feature exists, create click and hover listeners on the dep bullet
    if (feature) {
      const featureId = formatID(`single-${feature.get('LayerGroup')}-${labelName}`);
      popupList.forEach((popup) => {
        if (popup.popupId === featureId) {
          const infoContainer = document.getElementById(featureId);
          if (infoContainer) {
            infoContainer.style.border = 'solid 2px blue';
          }
        }
      });
    }
  }

  /**
    * Event listener for mouseout on labels in graph
    * un-highlights corresponding popup of hovered label
    * @param {string} labelName   The name of the label in the graph
    *
  */
  labelMouseOut(labelName) {
    const { spatialEnts, nonSpatialEnts, map } = this.props;
    const feature = getEntityFeature(labelName, spatialEnts, nonSpatialEnts, map);
    const featureId = formatID(`single-${feature.get('LayerGroup')}-${labelName}`);
    const infoContainer = document.getElementById(featureId);
    if (infoContainer) {
      infoContainer.style.border = '';
    }
  }

  /*
    Helper method to draw the legend on the graph (times)
  */
  drawLegend(minWidth, graphWidth, tickValues) {
    const legendContainer = document.getElementById('graph-legend');
    const legend = d3.select(legendContainer)
      .append('svg')
      .attr('id', 'legend-svg')
      .attr('width', graphWidth + 20)
      .attr('padding-right', 20);

    // create X axis
    const xScale = d3.scaleLinear()
      .domain([0, this.graphMax])
      .range([0, graphWidth]);

    // format axis based on width of graph and range of values
    const { max } = this;
    // only show number label for some ticks
    const labelTickRate = (graphWidth <= minWidth && max >= 100) ? 50 : 10;
    // separate "major" ticks from "minor" ticks
    const majorTickRate = (graphWidth <= minWidth && max >= 100) ? 50 : 5;
    legend.append('g')
      .attr('class', 'tick')
      .call(d3.axisTop(xScale)
        .tickSize(10) // size for major ticks (adjusted for minor ticks below)
        .tickValues(tickValues)
        .tickFormat((d) => (((
          // for some reason undefined was removing the 0 at the start
          d % labelTickRate !== 0) && (d !== (0 || undefined)) && (d !== max))
          ? '' : d)))
      .attr('transform', 'translate(10,39)');

    d3.selectAll('g')
      .filter((d) => ((d % majorTickRate !== 0) && (d !== (0 || undefined)) && (d !== max)))
      .style('stroke-dasharray', 6); // size for minor ticks
  }

  /*
    Helper method to draw the actual graph part
  */
  drawGraph(graphWidth, graphContainer, graphHeight, yScale) {
    const { sliderTime } = this.props;
    // Create graph container
    const svg = d3.select(graphContainer)
      .append('svg')
      .attr('id', 'graph-svg')
      .attr('width', graphWidth)
      .attr('height', graphHeight)
      .attr('padding-right', 20);

    // add Y axis to graph
    svg.append('g')
      .call(d3.axisLeft(yScale))
      .attr('id', 'y-axis');

    // Create Rows of Rectangles
    svg.selectAll('rect.row')
      .data(this.offlineData)
      .enter()
      .append('rect')
      .attr('x', 0)
      .attr('y', (d, i) => i * this.rectSpacing)
      .attr('width', (d) => yScale(d))
      .attr('height', this.rectHeight)
      .attr('fill', offlineColor)
      .attr('class', 'offline-bar')
      .exit()
      .data(this.operationalData)
      .enter()
      .append('rect')
      .attr('x', (d) => (yScale(this.graphMax - d)))
      .attr('y', (d, i) => i * this.rectSpacing)
      .attr('height', this.rectHeight)
      .attr('width', (d) => yScale(d))
      .attr('fill', operationalColor)
      .attr('class', 'operational-bar');

    // Draw current time line
    svg.append('line')
      .attr('x1', yScale(sliderTime))
      .attr('y1', 0)
      .attr('x2', yScale(sliderTime))
      .attr('y2', graphHeight - 5)
      .attr('id', 'current-time-line')
      .style('stroke-width', 2)
      .style('stroke', 'blue')
      .style('fill', 'none');

    const yAxis = document.getElementById('y-axis');
    yAxis.addEventListener('mousedown', this.mouseDown);
    document.addEventListener('mouseup', this.mouseUp);
  }

  drawArrows(graphHeight) {
    const { dispatch, graphList } = this.props;
    const arrowContainer = document.getElementById('arrows');
    const svg = d3.select(arrowContainer)
      .append('svg')
      .attr('id', 'arrows-svg')
      .attr('width', 20)
      .attr('height', graphHeight);

    svg.append('g')
      .attr('id', 'ys');

    svg.selectAll('rect.rows')
      .data(this.names)
      .enter()
      .append('svg:image')
      .attr('xlink:href', upArrow)
      .attr('class', 'graph-arrow')
      .attr('id', (d) => formatID(`up-${d}`))
      .attr('x', 0)
      .attr('y', (d, i) => i * this.rectSpacing + 2)
      .attr('height', 10)
      .attr('width', 10)
      .exit()
      .data(this.names)
      .enter()
      .append('svg:image')
      .attr('xlink:href', downArrow)
      .attr('class', 'graph-arrow')
      .attr('id', (d) => formatID(`down-${d}`))
      .attr('x', 0)
      .attr('y', (d, i) => i * this.rectSpacing + 18)
      .attr('height', 10)
      .attr('width', 10);

    graphList.forEach((item, index) => {
      const up = document.getElementById(formatID(`up-${item.name}`));
      const down = document.getElementById(formatID(`down-${item.name}`));
      up.onclick = () => {
        // We don't want to move it up or down if it is going to go outside of array range
        if (index - 1 >= 0) {
          dispatch(reorderGraphList(index, 'up'));
        }
      };
      down.onclick = () => {
        if (index + 1 >= 0) {
          dispatch(reorderGraphList(index, 'down'));
        }
      };
    });
  }


  /*
    Helper method to create the entire graph. This is called when component mounts.
  */
  drawChart() {
    const { graphList, max, searchPanelShow } = this.props;
    const timelineContainer = document.getElementById('graph-contents');
    const { scrollTop } = timelineContainer;
    const prevListSize = this.names.length;
    const graphContainer = document.getElementById('graph');
    if (!graphContainer.getBoundingClientRect().width) {
      return;
    }
    const graphSvg = document.getElementById('graph-svg');
    const legendSvg = document.getElementById('legend-svg');
    const labelSvg = document.getElementById('labels-svg');
    const arrowsSvg = document.getElementById('arrows-svg');
    d3.select(graphSvg)
      .remove();
    d3.select(legendSvg)
      .remove();
    d3.select(labelSvg)
      .remove();
    d3.select(arrowsSvg)
      .remove();

    // Create variables for graph
    this.graphMax = max;
    const pageWidth = document.body.clientWidth;
    // Width of graph is based on labels, arrows, search panel etc
    let graphWidth = pageWidth - 380;
    if (searchPanelShow) {
      graphWidth = pageWidth - 684;
    }
    const graphHeight = this.rectSpacing * graphList.length;
    this.offlineData = [];
    this.operationalData = [];
    this.names = [];

    const minLegendWidth = 800; // minimum width before axis must be simplified
    const tickValues = [];
    // number of ticks determined by width of graph and range of values
    const tickInc = (graphWidth <= minLegendWidth && this.graphMax >= 100) ? 10 : 1;
    for (let i = 0; i <= this.graphMax; i += tickInc) {
      tickValues.push(i);
    }
    // add max change time if missing in array of tick values
    if (tickValues[-1] !== this.graphMax) {
      tickValues.push(this.graphMax);
    }

    graphList.forEach((item) => {
      this.offlineData.push(item.Offline);
      this.operationalData.push(item.Operational);
      this.names.push(item.name);
    });

    // Create y Axis
    const yScale = d3.scaleLinear()
      .range([0, graphWidth])
      .domain([0, this.graphMax]);

    this.drawLegend(minLegendWidth, graphWidth, tickValues);
    this.drawLabels(graphHeight);
    this.drawGraph(graphWidth, graphContainer, graphHeight, yScale);
    this.drawArrows(graphHeight);

    // This is so scroll doesn't reset to top when item added, and so
    // when item is added the graph scrolls to bottom.
    if (this.names.length > prevListSize) {
      timelineContainer.scrollTop = timelineContainer.scrollHeight;

    // If not new item added, maintain scroll position
    } else {
      timelineContainer.scrollTop = scrollTop;
    }
  }

  /*
    This method listens for when the window width changes and resizes the graph
  */
  windowResizeListener() {
    const graph = document.getElementById('graph');
    graph.style.width = `${window.innerWidth - 570}px`;
    this.drawChart();
  }

  // This creates the item listener for the 'x' button beside each graph element
  removeItemListener() {
    const { graphList, dispatch } = this.props;
    graphList.forEach((item) => {
      const closer = document.getElementById(formatID(`closer-${item.name}`));
      closer.onclick = () => {
        dispatch(removeFromGraphList(item));
      };
    });
  }


  render() {
    const { graphList, searchPanelShow } = this.props;
    return (
      <>

        <div className={searchPanelShow ? 'graph-container' : 'graph-container full'}>
          {
            graphList.length > 0
            && <div id="graph-legend" />
          }
          <div id="graph-contents">
            <div id="arrows" />
            <div id="graph-label-closers" style={{ float: 'left' }}>
              {graphList.map((item) => (
                <div
                  key={formatID(`closer-${item.name}`)}
                  id={formatID(`closer-${item.name}`)}
                  className="graph-close-button"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              ))}
            </div>
            <div id="graph-labels" />

            <div id="graph" />

          </div>
        </div>
      </>
    );
  }
}

TimelineGraph.propTypes = {
  map: mapType,
  nonSpatialEnts: nonSpatialEntsType,
  spatialEnts: spatialEntsType,
  sliderTime: PropTypes.number,
  dispatch: PropTypes.elementType,
  graphList: graphListType,
  max: PropTypes.number,
  popupList: popupListType,
  searchPanelShow: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  map: state.map,
  nonSpatialEnts: state.nonSpatialEnts,
  spatialEnts: state.spatialEnts,
  sliderTime: state.sliderTime,
  graphList: state.graphList,
  popupList: state.popupList,
});

export default connect(mapStateToProps)(TimelineGraph);
