import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Feature } from 'ol';
import Overlay from 'ol/Overlay';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { addToPopupList, removeFromPopupList } from '../actions';
import '../App.css';
import { mapType, popupInfoType, popupListType } from '../types';
import duplicatePopupExists from '../utils/duplicatePopupExists';
import formatID from '../utils/formatID';
import getFeatureStateAsColour from '../utils/getFeatureStateAsColour';

class MultiFeaturePopup extends React.Component {
  constructor(props) {
    super(props);

    this.modelEntLayer = [];
    this.supDataLayer = [];
    this.initStLayer = [];

    const { features } = this.props;

    // seperate features into seperate lists for each layer group
    features.forEach((feature) => {
      if (feature.get('LayerGroup') === 'Spatial Entity') {
        this.modelEntLayer.push(feature);
      } else if (feature.get('LayerGroup') === 'Spatial Initial State') {
        this.initStLayer.push(feature);
      } else if (feature.get('LayerGroup') === 'Spatial Supporting Data') {
        this.supDataLayer.push(feature);
      }
    });
  }

  // LIFECYCLE METHODS

  componentDidMount() {
    const { popupId, popupCoords, map } = this.props;
    this.createClickListeners();
    const elementId = popupId;
    const containerId = `shell-${popupId}`;
    const overlay = new Overlay({ element: document.getElementById(containerId), id: elementId });
    const popupPosition = [];
    // This is so the lock never appears outside the viewable part of the map
    const [popupX, popupY] = popupCoords;
    if (popupX > 50) {
      popupPosition[0] = popupX;
    } else {
      popupPosition[0] = 50;
    }
    if (popupY > 170) {
      popupPosition[1] = popupY;
    } else {
      popupPosition[1] = 170;
    }
    map.addOverlay(overlay);
    overlay.setPosition(map.getCoordinateFromPixel(popupPosition));
  }

  /*  This method creates a click listener on every bullet in the
    multi-popup list.
  */
  createClickListeners() {
    const {
      features, popupId, map, dispatch,
    } = this.props;
    const multiPopup = document.getElementById(popupId);
    // Highlight features when popup is clicked on
    multiPopup.onclick = () => {
      const { highlightFeature, clearInteractions } = this.props;
      clearInteractions();
      features.forEach((feature) => {
        highlightFeature(feature);
      });
    };
    // Add a click listener to each item
    // This function creates a click listener to each item in popup's list
    features.map((feature) => {
      const featureID = formatID(`${popupId}-${feature.get('LayerGroup')}-${feature.get('Name')}`);
      const featureSpan = document.getElementById(featureID);
      const bulletPopupId = formatID(`single-${feature.get('LayerGroup')}-${feature.get('Name')}`);
      featureSpan.onclick = () => {
        const { popupInfo, popupCoords } = this.props;
        // Destructuring here again because createClickListeners is only called on Popup
        // Creation, while onclick can be called afterwards (meaning coords can change after
        // initialization)
        if (!(duplicatePopupExists(feature, map))) {
          // add popup for bullet that is clicked on
          dispatch(addToPopupList({ feature: [feature], popupId: bulletPopupId, popupCoords }));
          // delete current popup
          dispatch(removeFromPopupList(popupInfo));
        }
      };
      // If moused over, highlight feature popup
      featureSpan.onmouseover = () => {
        const { popupList } = this.props;
        popupList.forEach((popup) => {
          if (popup.popupId === bulletPopupId) {
            const infoContainer = document.getElementById(bulletPopupId);
            if (infoContainer) {
              infoContainer.style.border = 'solid 2px blue';
            }
          }
        });
      };
      // If moused out, unhighlight feature popup
      featureSpan.onmouseout = () => {
        // give border to popup when corresponding name is highlighted
        const infoContainer = document.getElementById(bulletPopupId);
        if (infoContainer) {
          infoContainer.style.border = '';
        }
      };
      return false;
    });
  }

  render() {
    const { popupId, time } = this.props;
    return (
      <>
        <div className="ol-popup info-container" key={popupId} id={popupId}>
          {this.modelEntLayer.length > 0
            && (
              <>
                <h5 style={{ marginLeft: '30px' }}>Entities (Spatial)</h5>
                <ul>
                  {this.modelEntLayer.map((feature) => (
                    <li
                      data-testid={feature.get('Name')}
                      style={{ listStyle: 'none' }}
                      key={popupId + feature.get('Name').replace(/[^0-9a-z]/gi, '')}
                      className={feature.get('Name').replace(/[^0-9a-z]/gi, '')}
                    >
                      <span className="state-bullet">
                        <FontAwesomeIcon
                          data-testid={`${feature.get('Name')}color`}
                          color={getFeatureStateAsColour(feature, time)}
                          size="2x"
                          icon={faMapMarkerAlt}
                        />
                      </span>
                      <span
                        style={{ cursor: 'pointer' }}
                        key={formatID(`${popupId}dep${feature.get('Name')}`)}
                        id={formatID(`${popupId}-${feature.get('LayerGroup')}-${feature.get('Name')}`)}
                        className="popup-text"
                      >
                        {feature.get('Name')}
                      </span>

                    </li>
                  ))}
                </ul>
              </>
            )}
          {this.supDataLayer.length > 0 && (
            <>
              <h5 style={{ marginLeft: '30px' }}>Supporting Data (Spatial)</h5>
              <ul>
                {this.supDataLayer.map((feature) => (
                  <li
                    data-testid={feature.get('Name')}
                    style={{ listStyle: 'none' }}
                    key={feature.get('Name').replace(/[^0-9a-z]/gi, '')}
                    className="supDataLayer"
                  >
                    <span className="state-bullet"><FontAwesomeIcon data-testid={`${feature.get('Name')}color`} color="black" size="2x" icon={faMapMarkerAlt} /></span>
                    <span
                      style={{ cursor: 'pointer' }}
                      key={feature.get('Name')}
                      id={formatID(`${popupId}-${feature.get('LayerGroup')}-${feature.get('Name')}`)}
                      className="popup-text"
                    >
                      {feature.get('Name')}
                    </span>
                  </li>
                ))}
              </ul>
            </>
          )}
          {this.initStLayer.length > 0
            && (
              <>
                <h5 style={{ marginLeft: '30px' }}>Initial State Values (Spatial)</h5>
                <ul>
                  {this.initStLayer.map((feature) => (
                    <li
                      data-testid={feature.get('Name')}
                      style={{ listStyle: 'none' }}
                      key={feature.get('Name').replace(/[^0-9a-z]/gi, '')}
                      className="initialStLayer"
                    >
                      <span className="state-bullet">
                        <FontAwesomeIcon
                          data-testid={`${feature.get('Name')}color`}
                          color={getFeatureStateAsColour(feature, time)}
                          size="2x"
                          icon={faMapMarkerAlt}
                        />
                      </span>
                      <span
                        style={{ cursor: 'pointer' }}
                        key={feature.get('Name')}
                        id={formatID(`${popupId}-${feature.get('LayerGroup')}-${feature.get('Name')}`)}
                        className="popup-text"
                      >
                        {feature.get('Name')}
                      </span>
                    </li>
                  ))}
                </ul>
              </>
            )}
        </div>
      </>
    );
  }
}

MultiFeaturePopup.propTypes = {
  dispatch: PropTypes.elementType.isRequired,
  features: PropTypes.arrayOf(PropTypes.instanceOf(Feature)).isRequired,
  map: mapType,
  popupCoords: PropTypes.arrayOf(PropTypes.number).isRequired,
  popupId: PropTypes.string.isRequired,
  time: PropTypes.number.isRequired,
  highlightFeature: PropTypes.elementType.isRequired,
  clearInteractions: PropTypes.elementType.isRequired,
  popupInfo: popupInfoType,
  popupList: popupListType,
};

const mapStateToProps = (state) => ({
  map: state.map,
  time: state.sliderTime,
  interactions: state.interactions,
  popupList: state.popupList,
});

export default connect(mapStateToProps)(MultiFeaturePopup);
