import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Feature from 'ol/Feature';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { addToPopupList } from '../actions';
import { mapType, nonSpatialEntsType, popupListType } from '../types';
import { offlineColor, operationalColor } from '../utils/colors';
import duplicatePopupExists from '../utils/duplicatePopupExists';
import formatID from '../utils/formatID';
import getFeaturePixelPosition from '../utils/getFeaturePixelPosition';

class NonSpatialEnsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false, // Toggle the NonSpatialEnsList open/closed
      modelEntShow: true, // Toggle the visibility of model entity layers in list
      initStShow: true, // Toggle the visibility of initial state layers in list
    };
    this.listeningDivs = [];

    this.clickListen = this.clickListen.bind(this);
    this.hideList = this.hideList.bind(this);
    this.toggleModelEntities = this.toggleModelEntities.bind(this);
    this.toggleInitialEntities = this.toggleInitialEntities.bind(this);
    this.nameMouseClick = this.nameMouseClick.bind(this);
    this.nameMouseOver = this.nameMouseOver.bind(this);
    this.nameMouseOut = this.nameMouseOut.bind(this);
  }

  /* This method gets the initial state for initial state layers in the list.
  */
  getInitialState(dependency) {
    const { nonSpatialEnts } = this.props;
    const nonSpatialEnt = nonSpatialEnts[dependency];
    if (nonSpatialEnt.InitialState === 1) {
      return operationalColor;
    } if (nonSpatialEnt.InitialState === 0) {
      return offlineColor;
    }
    return 'black';
  }

  // LIFECYCLE METHODS

  componentDidMount() {
    const { nonSpatialEnts } = this.props;
    const nonSpatialButton = document.getElementById('non-spatial-button');
    nonSpatialButton.addEventListener('click', this.clickListen);
    Object.keys(nonSpatialEnts).map((entName) => {
      // Set up non-spatial entity listeners
      const initialID = formatID(`init-${entName}`);
      const initialFeatureSpan = document.getElementById(initialID);
      const initFeature = new Feature({
        Name: entName,
        LayerGroup: 'Non Spatial Initial State',
        Dependencies: [],
      });
      if (nonSpatialEnts[entName].InitialState) {
        initFeature.set('InitialState', nonSpatialEnts[entName].InitialState);
      }
      if (initialFeatureSpan) {
        initialFeatureSpan.addEventListener('click', this.nameMouseClick.bind(null, entName, initFeature));
        initialFeatureSpan.addEventListener('mouseover', this.nameMouseOver.bind(null, entName, initFeature));
        initialFeatureSpan.addEventListener('mouseout', this.nameMouseOut.bind(null, entName, initFeature));
        this.listeningDivs.push(initialFeatureSpan);
      }

      // Set up spatial entity listeners
      const featureID = formatID(`dropdown-${entName}`);
      const featureSpan = document.getElementById(featureID);
      const feature = new Feature({
        Name: entName,
        LayerGroup: 'Non Spatial Entity',
        Dependencies: nonSpatialEnts[entName].Dependencies,
        EntityState: nonSpatialEnts[entName].EntityState,
        ImmediateEffects: nonSpatialEnts[entName].ImmediateEffects,
      });
      if (featureSpan) {
        featureSpan.addEventListener('click', this.nameMouseClick.bind(null, entName, feature));
        featureSpan.addEventListener('mouseover', this.nameMouseOver.bind(null, entName, feature));
        featureSpan.addEventListener('mouseout', this.nameMouseOut.bind(null, entName, feature));
        this.listeningDivs.push(featureSpan);
      }
      return false;
    });
    // When the hide button is clicked, it hides the dropdown list.
    const toggleButton = document.getElementById('toggle-controls-button-id');
    if (toggleButton) {
      toggleButton.addEventListener('click', this.hideList);
    }
  }

  componentWillUnmount() {
    const nonSpatialButton = document.getElementById('non-spatial-button');
    if (nonSpatialButton) {
      nonSpatialButton.removeEventListener('click', this.clickListen);
    }
    this.listeningDivs.forEach((div) => {
      div.removeEventListener('click', this.nameMouseClick);
      div.removeEventListener('mouseover', this.nameMouseOver);
      div.removeEventListener('mouseout', this.nameMouseOut);
    });
    const toggleButton = document.getElementById('toggle-controls-button-id');
    if (toggleButton) {
      toggleButton.removeEventListener('click', this.hideList);
    }
  }


  /* This method gets the entity state for model entity layers in the list.
  */
  getEntityState(dependency, time) {
    const { nonSpatialEnts } = this.props;
    let colour = '#000000';
    const nonSpatialEnt = nonSpatialEnts[dependency];
    if (nonSpatialEnt.ChangeTime === undefined) {
      if (nonSpatialEnt.EntityState === 1) {
        colour = operationalColor;
      } else if (nonSpatialEnt.EntityState === 0) {
        colour = offlineColor;
      }
    } else if (time < nonSpatialEnt.ChangeTime) {
      colour = offlineColor;
    } else if (time >= nonSpatialEnt.ChangeTime) {
      colour = operationalColor;
    }
    return colour;
  }

  /* This method gets the list items for the initial state list.
*/
  getInitialItem(featName) {
    const { nonSpatialEnts } = this.props;
    const { initStShow } = this.state;

    if ('InitialState' in nonSpatialEnts[featName]) {
      return ((
        <li
          key={`init-${featName}`}
          className={initStShow ? 'non-spatial-list-item' : 'hide'}
        >
          <span
            className="state-bullet"
            data-testid="state-bullet"
          >
            <FontAwesomeIcon
              color={this.getInitialState(featName)}
              data-testid={`init${featName}color`}
              size="2x"
              icon={faFileAlt}
            />
          </span>
          <span
            id={formatID(`init-${featName}`)}
            className="popup-text"
            style={{ cursor: 'pointer' }}
          >
            {' '}
            {featName}
          </span>
        </li>
      ));
    }
    return null;
  }

  /**
    * Event listener for mouseclick on names in nonSpatialEnsList
    * opens a new popup if not one already open
    * @param {string} entName    The name of the item
    * @param {Feature} feature   The feature corresponding to the clickable name
    *
  */
  nameMouseClick(entName, feature) {
    const {
      map, popupList, nonSpatialEnts, dispatch,
    } = this.props;

    const featureId = formatID(`single-${feature.get('LayerGroup')}-${feature.get('Name')}`);

    if ('ChangeTime' in nonSpatialEnts[entName]) {
      feature.set('ChangeTime', nonSpatialEnts[entName].ChangeTime);
    }
    if (!(duplicatePopupExists(feature, map))) {
      const coord = getFeaturePixelPosition(
        feature, map, popupList.length, window.innerWidth,
      );
      dispatch(addToPopupList({
        feature: [feature], popupId: featureId, popupCoords: coord,
      }));
    }
  }
  // Turning this off for this method,
  // as we need it to be a method so we can register events to it
  /* eslint class-methods-use-this: "off" */

  /**
  * Event listener for mouseover on names in nonSpatialEnsList
  * highlights corresponding popup of hovered name
  * @param {string} entName    The name of the item
  * @param {Feature} feature   The feature corresponding to the clickable name
  *
*/
  nameMouseOver(entName, feature) {
    const { popupList } = this.props;
    // If the feature exists, create click and hover listeners on the dep bullet
    if (feature) {
      const featureId = formatID(`single-${feature.get('LayerGroup')}-${entName}`);
      popupList.forEach((popup) => {
        if (popup.popupId === featureId) {
          const infoContainer = document.getElementById(featureId);
          if (infoContainer) {
            infoContainer.style.border = 'solid 2px blue';
          }
        }
      });
    }
  }

  /**
  * Event listener for mouseclick on names in nonSpatialEnsList
  * un-highlights corresponding popup of hovered name
  * @param {string} entName    The name of the item
  * @param {Feature} feature   The feature corresponding to the clickable name
  *
*/
  nameMouseOut(entName, feature) {
    const featureId = formatID(`single-${feature.get('LayerGroup')}-${entName}`);
    const infoContainer = document.getElementById(featureId);
    if (infoContainer) {
      infoContainer.style.border = '';
    }
  }


  /* This method toggles the visibility of the dropdown/list
  */
  clickListen() {
    const { open } = this.state;
    if (open === false) {
      this.setState({ open: true });
    } else {
      this.setState({ open: false });
    }
  }
  
  /* Hides dropdown/list */
  hideList() {
    this.setState({ open: false });
  }

  /* This method collapses/shows the model entities in the Non Spatial List
    Dropdown.
  */
  toggleModelEntities() {
    const { modelEntShow } = this.state;
    const nonSpatialInfo = document.getElementById('non-spatial-inside');
    nonSpatialInfo.style.width = `${nonSpatialInfo.getBoundingClientRect().width}px`;
    if (modelEntShow) {
      this.setState({ modelEntShow: false });
    } else {
      this.setState({ modelEntShow: true });
    }
  }

  /*  This method collapses/shows the initial state entities in the
      Non Spatial List Dropdown.
  */
  toggleInitialEntities() {
    const { initStShow } = this.state;
    const nonSpatialInfo = document.getElementById('non-spatial-inside');
    nonSpatialInfo.style.width = `${nonSpatialInfo.getBoundingClientRect().width}px`;
    if (initStShow) {
      this.setState({ initStShow: false });
    } else {
      this.setState({ initStShow: true });
    }
  }

  render() {
    const { nonSpatialEnts, time } = this.props;
    const { open, modelEntShow, initStShow } = this.state;
    const sortedList = Object.keys(nonSpatialEnts).sort(
      (a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1),
    );
    return (
      <>
        <div id="non-spatial-list" className="ol-unselectable ol-control non-spatial">
          <button
            type="button"
            id="non-spatial-button"
            data-testid="non-spatial-button"
          >
            <FontAwesomeIcon icon={faFileAlt} className="non-spatial-ens-list" size="lg" />
          </button>
        </div>
        <div
          id="non-spatial-inside"
          data-testid="non-spatial-inside"
          className={open ? 'non-spatial-content show-content' : 'non-spatial-content'}
        >
          <div style={{ margin: '0 20px' }}>
            {Object.keys(nonSpatialEnts).length > 0
              && (
                <>
                  <span style={{ display: 'inline' }} className="non-spatial-ctrl">
                    <Button
                      id="ent-list-collapse"
                      className={modelEntShow ? 'grouping-fold-button list-button-show' : 'grouping-fold-button'}
                      onClick={this.toggleModelEntities}
                    />
                    <h6 style={{ display: 'inherit', fontWeight: 'bold' }}> Entities (Non Spatial)</h6>
                  </span>
                </>
              )}
            <ul>
              {sortedList.map((featName) => (
                <li
                  className={modelEntShow ? 'non-spatial-list-item' : 'hide'}
                  key={featName}
                >
                  <span
                    className="state-bullet"
                    data-testid="state-bullet"
                  >
                    <FontAwesomeIcon
                      data-testid={`${featName}color`}
                      color={this.getEntityState(featName, time)}
                      size="2x"
                      icon={faFileAlt}
                    />
                  </span>
                  <span
                    id={formatID(`dropdown-${featName}`)}
                    className="popup-text"
                    style={{ cursor: 'pointer' }}
                  >
                    {' '}
                    {featName}
                  </span>
                </li>
              ))}
            </ul>

            {Object.keys(nonSpatialEnts).length > 0
              && (
                <>
                  <span style={{ display: 'inline' }} className="non-spatial-ctrl">
                    <Button
                      size="xs"
                      id="init-st-list-collapse"
                      className={initStShow ? 'grouping-fold-button list-button-show' : 'grouping-fold-button'}
                      onClick={this.toggleInitialEntities}
                    />
                    <h6 style={{ display: 'inherit', fontWeight: 'bold' }}> Initial State Values (Non Spatial)</h6>
                  </span>
                  <br />
                </>
              )}
            <ul>
              {sortedList.map((key) => this.getInitialItem(key))}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

NonSpatialEnsList.propTypes = {
  dispatch: PropTypes.elementType.isRequired,
  nonSpatialEnts: nonSpatialEntsType,
  map: mapType,
  popupList: popupListType,
  time: PropTypes.number,
};

const mapStateToProps = (state) => ({
  map: state.map,
  time: state.sliderTime,
  selectedCaseId: state.selectedCaseId,
  popupList: state.popupList,
});

export default connect(mapStateToProps)(NonSpatialEnsList);
