import * as extent from 'ol/extent';
import { GeoJSON } from 'ol/format';
import LayerGroup from 'ol/layer/Group';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { immedEffectsStyleFunction, initStateStyleFunction } from './featureStyles';


export function createVectorLayer(srcUrl, title, layerGroup, initial) {
  const vectorLayer = new VectorLayer({
    source: new VectorSource({
      // get features from GeoJSON file
      url: srcUrl,
      format: new GeoJSON({
        featureProjection: 'EPSG:3857',
      }),
    }),
    style: initial ? initStateStyleFunction : immedEffectsStyleFunction,
    title,
    LayerGroup: layerGroup,
  });

  vectorLayer.setVisible(true);

  return vectorLayer;
}

export function getVisibleExtent(map) {
  const ext = extent.createEmpty();
  map.getLayers().getArray().forEach((lyrGroup) => {
    if (lyrGroup instanceof LayerGroup && lyrGroup.get('title') !== 'Base Maps') {
      lyrGroup.getLayers().forEach((layer) => {
        if (layer.getVisible()) {
          extent.extend(ext, layer.getSource().getExtent());
        }
      });
    }
  });
  return ext;
}

export function updateMapExtent(map, animate) {
  const ext = getVisibleExtent(map);
  if (ext[0] !== Infinity) {
    const view = map.getView();
    view.fit(ext, { size: map.getSize(), padding: [36, 36, 36, 36], duration: animate ? 1000 : 0 });
  }
}
