import { Map } from 'ol';
import Feature from 'ol/Feature';
import Select from 'ol/interaction/Select';
import {
  arrayOf, bool, exact, instanceOf, number,
  objectOf, oneOf, shape, string, oneOfType,
} from 'prop-types';

/** This is a one liner, but it is easier to put here
  * as you can modify it once instead of multiple times if needed
*/
export const mapType = instanceOf(Map);

export const interactionsType = instanceOf(Select);

/** visible_layers: "all"
  * OR
  * visible_layers: {
  *   <LayerGroupName>: "all"
  *   OR
  *   <LayerGroupName>: ["<layer1>", "<layer2>", ...]
  * }
*/
const visibleLayersType = oneOfType([string,
  shape({
    'Entities (Spatial)': oneOfType([string, arrayOf(string)]),
    'Initial State Values (Spatial)': oneOfType([string, arrayOf(string)]),
    'Supporting Data (Spatial)': oneOfType([string, arrayOf(string)]),
  }),
]);

export const casesByIdType = objectOf(
  shape({
    name: string,
    id: number,
    description: string,
    ent_files_dir: string,
    ent_files: arrayOf(string),
    sup_files_dir: string,
    sup_files: arrayOf(string),
    init_st_files_dir: string,
    init_st_files: arrayOf(string),
    max_time: number,
    visible_layers: visibleLayersType,
    timeline_preload_ents: oneOfType([
      arrayOf(string),
      string,
    ]),
  }),
);

// exact means will throw an error if extra attribute added
export const scensByIdType = objectOf(
  exact({
    description: string,
    id: number,
    name: string,
    cases: arrayOf(number),
  }),
);

// exact means will throw an error if extra attribute added
export const modelsByIdType = objectOf(
  exact({
    attributes: oneOfType([
      arrayOf(string).isRequired,
      string,
    ]),
    description: string,
    id: number,
    name: string,
    scenarios: arrayOf(number),
    time_unit: oneOf(['hours', 'days', 'weeks']),
    type: string,
    subtype: string,
  }),
);

// In arrays, we can require types for each item
// but in objects we have to be able to expect an
// empty object
export const lyrType = arrayOf(
  exact({
    url: string.isRequired,
    title: string.isRequired,
    visible: bool.isRequired,
  }),
);

// TODO: Can make this strict once #91 is merged as won't have click or hover types
export const popupInfoType = shape({
  feature: arrayOf(instanceOf(Feature)).isRequired,
  popupId: string.isRequired,
  popupCoords: arrayOf(number).isRequired,
});

export const popupListType = arrayOf(popupInfoType);

export const spatialEntsType = objectOf(
  shape({
    Name: string,
    LayerGroup: oneOf(['Spatial Entity', 'Spatial Initial State', 'Spatial Supporting Data']),
    Layer: string,
    Dependencies: arrayOf(string),
    InitialState: oneOf([0, 1]),
    ImmediateEffects: oneOf([0, 1]),
    EntityState: oneOf([0, 1]),
  }),
);

export const nonSpatialEntsType = objectOf(
  shape({
    Name: string,
    Dependencies: arrayOf(string),
    InitialState: oneOf([0, 1]),
    ImmediateEffects: oneOf([0, 1]),
    EntityState: oneOf([0, 1]),
  }),
);

export const legendInfoType = objectOf(
  objectOf(
    shape({
      Color: string.isRequired,
    }),
  ),
);

export const groupInfoType = objectOf(
  shape({
    Color: string.isRequired,
  }),
);

export const ModelInfoType = shape({
  name: string.isRequired,
  id: number.isRequired,
  description: string,
  attributes: oneOfType([
    arrayOf(string).isRequired,
    string,
  ]),
  time_unit: oneOf(['hours', 'days', 'weeks']),
  type: string,
});


export const caseInfoType = shape({
  name: string,
  id: number,
  description: string,
  ent_files_dir: string,
  ent_files: arrayOf(string),
  sup_files_dir: string,
  sup_files: arrayOf(string),
  init_st_files_dir: string,
  init_st_files: arrayOf(string),
  max_time: number,
  visible_layers: visibleLayersType,
  timeline_preload_ents: oneOfType([
    arrayOf(string),
    string,
  ]),
  preload_popups: oneOfType([string,
    shape({
      'Entities (Spatial)': oneOfType([string, arrayOf(string)]),
      'Initial State Values (Spatial)': oneOfType([string, arrayOf(string)]),
      'Supporting Data (Spatial)': oneOfType([string, arrayOf(string)]),
      'Entities (Non Spatial)': oneOfType([string, arrayOf(string)]),
      'Initial State Values (Non Spatial)': oneOfType([string, arrayOf(string)]),
    }),
  ]),
});

export const graphListType = arrayOf(
  exact({
    name: string.isRequired,
    Offline: number.isRequired,
    Operational: number.isRequired,
  }),
);

// this can be scenario, model, or case so adding
// keys from all three to a shape
export const elementsByIdType = objectOf(
  shape({
    name: string,
    description: string,
    id: number,
    attributes: oneOfType([
      arrayOf(string).isRequired,
      string,
    ]),
    scenarios: arrayOf(number),
    time_unit: oneOf(['hours', 'days', 'weeks']),
    type: string,
    ent_files_dir: string,
    ent_files: arrayOf(string),
    sup_files_dir: string,
    sup_files: arrayOf(string),
    init_st_files_dir: string,
    init_st_files: arrayOf(string),
    max_time: number,
    visible_layers: visibleLayersType,
    timeline_preload_ents: oneOfType([
      arrayOf(string),
      string,
    ]),
  }),
);
