const nonSpatialEnts = (state = {}, action) => {
  switch (action.type) {
    case 'SET_NON_SPATIAL_ENT_LIST':
      return action.entList;
    default:
      return state;
  }
};

export default nonSpatialEnts;
