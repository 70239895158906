const graphList = (state = [], action) => {
  switch (action.type) {
    case 'ADD_TO_GRAPH_LIST':
      return [
        ...state,
        action.graphItem,
      ];
    case 'REMOVE_FROM_GRAPH_LIST':
      return state.filter((graphItem) => graphItem !== action.graphItem);
    case 'REORDER_GRAPH_LIST':
      return [
        ...state.filter((item, index) => index !== action.graphIndex).slice(
          0, action.direction === 'up' ? action.graphIndex - 1 : action.graphIndex + 1,
        ),
        state[action.graphIndex],
        ...state.filter((item, index) => index !== action.graphIndex).slice(
          action.direction === 'up' ? action.graphIndex - 1 : action.graphIndex + 1,
        ),
      ];
    case 'CLEAR_GRAPH_LIST':
      return [];
    default:
      return state;
  }
};

export default graphList;
