import { connect } from 'react-redux';
import addNewModel from '../utils/addNewModel';
import App from '../components/App';

const mapDispatchToProps = (dispatch) => ({
  addModel: (model) => addNewModel(model, 'pre-inst', dispatch),
});

export default connect(
  null,
  mapDispatchToProps,
)(App);
