import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { addToPopupList, removeFromPopupList } from '../actions';
import '../App.css';
import {
  mapType, nonSpatialEntsType, popupInfoType, spatialEntsType,
} from '../types';
import duplicatePopupExists from '../utils/duplicatePopupExists';
import formatID from '../utils/formatID';
import getEntityFeature from '../utils/getEntityFeature';

class PopupViewSwitch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.layerGroup.includes('Initial State') ? 'initial' : 'entity',
    };
    const { featureName, nonSpatialEnts, spatialEnts } = this.props;
    this.nonSpatialEnt = null;
    this.spatialEnt = null;
    if (featureName in nonSpatialEnts) {
      this.nonSpatialEnt = nonSpatialEnts[featureName];
    } else if (featureName in spatialEnts) {
      this.spatialEnt = spatialEnts[featureName];
    }
    this.switchGroup = this.switchGroup.bind(this);
  }

  switchGroup(event) {
    const {
      featureName, spatialEnts, layerGroup, nonSpatialEnts, map, dispatch, popupInfo, popupCoords,
    } = this.props;
    let newLyrGroup;
    switch (layerGroup) {
      case 'Spatial Initial State':
        newLyrGroup = 'Spatial Entity';
        break;
      case 'Spatial Entity':
        newLyrGroup = 'Spatial Initial State';
        break;
      case 'Non Spatial Initial State':
        newLyrGroup = 'Non Spatial Entity';
        break;
      default: // 'Non Spatial Entity'
        newLyrGroup = 'Non Spatial Initial State';
    }

    const feature = getEntityFeature(featureName, spatialEnts, nonSpatialEnts, newLyrGroup, map);
    const featureId = formatID(`single-${newLyrGroup}-${featureName}`);

    const featureFound = feature !== -1;

    // replace popup
    if (featureFound && !(duplicatePopupExists(feature, map))) {
      dispatch(removeFromPopupList(popupInfo));
      dispatch(addToPopupList({ feature: [feature], popupId: featureId, popupCoords }));
      this.setState({ value: event.target.value });
    }
  }

  render() {
    const { layerGroup } = this.props;
    const { value } = this.state;
    if (layerGroup.includes('Initial State')) {
      return (
        <div style={{ textAlign: 'center' }}>
          <select
            style={{ display: 'inline-block' }}
            value={value}
            onChange={(e) => {
              this.switchGroup(e);
            }}
          >
            <option value="entity">view entity</option>
            <option value="initial">view initial state</option>

          </select>
        </div>
      );
    } // entity (spatial or non-spatial)
    return (
      <div style={{ textAlign: 'center' }}>
        <select
          style={{ display: 'inline-block' }}
          value={value}
          onChange={(e) => {
            this.switchGroup(e);
          }}
        >
          <option value="entity">view entity</option>
          {((this.spatialEnt && 'InitialState' in this.spatialEnt) || (this.nonSpatialEnt && 'InitialState' in this.nonSpatialEnt))
            ? <option value="initial">view initial state</option>
            : <option value="initial" disabled>no initial state available</option>}
        </select>
      </div>
    );
  }
}

PopupViewSwitch.propTypes = {
  dispatch: PropTypes.elementType.isRequired,
  featureName: PropTypes.string.isRequired,
  layerGroup: PropTypes.string.isRequired,
  map: mapType,
  nonSpatialEnts: nonSpatialEntsType,
  spatialEnts: spatialEntsType,
  popupInfo: popupInfoType,
  popupCoords: PropTypes.arrayOf(PropTypes.number).isRequired,
};

const mapStateToProps = (state) => ({
  map: state.map,
  nonSpatialEnts: state.nonSpatialEnts,
  spatialEnts: state.spatialEnts,
});

export default connect(mapStateToProps)(PopupViewSwitch);
