import { getCenter } from 'ol/extent';

export default function getFeaturePixelPosition(feature, map, popupNumber, windowWidth) {
  const featureGeom = feature.getGeometry();
  let position = [0, 0];
  if (featureGeom) {
    // Lines, polygons have more than 1 coordinate so first item is another array, not a value.
    if (typeof featureGeom.getCoordinates()[0] === 'object') {
      position = map.getPixelFromCoordinate(getCenter(featureGeom.getExtent()));
    // Get first coordinate works for all types of features
    } else {
      position = map.getPixelFromCoordinate(featureGeom.getFirstCoordinate());
    }

  // If the feature doesn't exist on the map just stagger them across top of
  // screen.
  } else {
    const startPositionX = 100;
    const shiftX = 50;

    const startPositionY = 200;
    const shiftY = 100;
    // this math allows them to stagger across top of screen
    let xPos = popupNumber * shiftX;
    let yPos = Math.floor(xPos / windowWidth); // will be 0,1,2 dependent how many times page wraps

    xPos %= windowWidth; // So they stop at far right of screen
    xPos += startPositionX; // starting position from left in px.

    yPos *= shiftY; // move down this much every time popups wrap back
    yPos += startPositionY; // starting position from top in px.
    position = [xPos, yPos];
  }
  return position;
}
