import LayerGroup from 'ol/layer/Group';

// helper function to get the layer the given entity belongs to
export default function getFeatureLayer(map, feature) {
  let entLayer = -1;
  let found = false;
  let layerGroupName;
  if (feature.get('LayerGroup').includes('Entity')) {
    layerGroupName = 'Entities (Spatial)';
  } else if (feature.get('LayerGroup').includes('Initial')) {
    layerGroupName = 'Initial State Values (Spatial)';
  } else {
    layerGroupName = 'Supporting Data (Spatial)';
  }

  // find layergroup containing layer entity is from
  map.getLayers().getArray().some((layer) => {
    if (layer instanceof LayerGroup && layer.get('title') === layerGroupName) {
      const vectorLayers = layer.getProperties().layers.getArray();
      // find layer containing entity
      vectorLayers.some((vectorLayer) => {
        if (vectorLayer.get('title') === feature.get('Layer')) {
          entLayer = vectorLayer;
          found = true;
        }
        return found;
      });
      return found;
    }
    return found;
  });
  return entLayer;
}
