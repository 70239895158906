import { faSort, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import SearchField from 'react-search-field';
import { addToGraphList, removeFromGraphList } from '../actions';
import {
  casesByIdType, mapType, nonSpatialEntsType, spatialEntsType, graphListType,
} from '../types';
import formatID from '../utils/formatID';
import TimelineGraph from './TimelineGraph';


class ExpandedTimeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResults: [],
      searchPanelShow: true,
      // describes current conditions of results table,
      // i.e. not currently sorted in ascending order by name or time
      nameSortedAsc: false,
      timeSortedAsc: false,
    };

    const { casesById, selectedCaseId } = this.props;

    // If timeline_preload_ents exists in model_info add into component
    if (casesById[selectedCaseId] && casesById[selectedCaseId].timeline_preload_ents) {
      // get spatial entities preloaded into timeline (if there are any)
      this.preloadedEntities = [];
      if (casesById[selectedCaseId].timeline_preload_ents === 'all') {
        const {
          spatialEnts, nonSpatialEnts,
        } = this.props;
        this.preloadedEntities = [...Object.keys(spatialEnts), ...Object.keys(nonSpatialEnts)];
      } else {
        // This makes sure everything in the list is unique
        const preloadedEnts = [...new Set(casesById[selectedCaseId].timeline_preload_ents)];
        if (preloadedEnts) {
          this.preloadedEntities = preloadedEnts;
        }
      }
    } else {
      this.preloadedEntities = [];
    }

    this.searchLength = 0;
    this.sortedEnts = [];

    // Function binding
    this.handleSearch = this.handleSearch.bind(this);
    this.resizeFunc = this.resizeFunc.bind(this);
    this.mouseDown = this.mouseDown.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
    this.makeSearchResultsClickable = this.makeSearchResultsClickable.bind(this);
    this.addPreloadedEntities = this.addPreloadedEntities.bind(this);
    this.getEntByName = this.getEntByName.bind(this);
    this.compareNames = this.compareNames.bind(this);
    this.compareTimes = this.compareTimes.bind(this);
    this.sortEntTable = this.sortEntTable.bind(this);
    this.sortByName = this.sortByName.bind(this);
    this.sortByTime = this.sortByTime.bind(this);
    this.duplicateGraphItem = this.duplicateGraphItem.bind(this);
    this.showSearchPanel = this.showSearchPanel.bind(this);
    this.hideSearchPanel = this.hideSearchPanel.bind(this);
  }

  componentDidMount() {
    const { allEntities } = this.props;
    this.sortedEnts = allEntities;
    // If any preloadedEnts configured in modelInfo, add to graph
    this.addPreloadedEntities();
  }

  // LIFESTYLE METHODS
  componentDidUpdate() {
    const { timelineExpanded, map } = this.props;
    const {
      searchResults,
    } = this.state;
    const mapDiv = document.getElementById('model-map');
    const div = document.getElementById('timeline-div');

    // Add event listeners for resizing when timeline is opened
    if (timelineExpanded) {
      if (div) {
        div.addEventListener('mousedown', this.mouseDown);
      }
      document.addEventListener('mouseup', this.mouseUp);
      const newMapSize = div.getBoundingClientRect().height + 65;
      if (mapDiv) {
        mapDiv.style.top = `${newMapSize}px`;
      }
      map.updateSize();
      const results = this.searchLength === 0 ? this.sortedEnts : searchResults;
      this.makeSearchResultsClickable(results);

    // Remove them when it is closed
    } else if (mapDiv) {
      mapDiv.style.top = '65px';
      if (div) {
        div.removeEventListener('mousedown', this.mouseDown);
      }
      document.removeEventListener('mouseup', this.mouseUp);
    }
  }

  componentWillUnmount() {
    const { graphList, dispatch } = this.props;
    // Wipe this to an empty list when we unmount (switching cases etc)
    graphList.forEach((graphItem) => {
      dispatch(removeFromGraphList(graphItem));
    });
    const div = document.getElementById('timeline-div');
    if (div) {
      div.removeEventListener('mousedown', this.mouseDown);
    }
    document.removeEventListener('mouseup', this.mouseUp);
  }

  /* This method takes an entity's name and returns an object with its offline
     and operational times
  */
  getEntByName(name) {
    const { nonSpatialEnts, spatialEnts, maxTime } = this.props;
    let offlineTime = 0;
    let operationalTime = maxTime;
    let entity;
    if (name in nonSpatialEnts || name in spatialEnts) {
      const props = name in nonSpatialEnts ? nonSpatialEnts[name] : spatialEnts[name];
      if (props.EntityState === 0) {
        if ('ChangeTime' in props) {
          offlineTime = props.ChangeTime;
          operationalTime = maxTime - offlineTime;
        } else {
          offlineTime = maxTime;
          operationalTime = 0;
        }
      }
      entity = { name, Offline: offlineTime, Operational: operationalTime };
    }

    if (entity) {
      return entity;
    }
    return false;
  }


  /* This is the callback method for the mousedown listener in componentDidUpdate
  */
  mouseDown(evt) {
  // left click only
    if (evt.button === 0) {
    // Resizing
      if (evt.target.className === 'resizer bottom') {
        document.addEventListener('mousemove', this.resizeFunc, false);
      }
    }
  }

  /* This is the callback method for the mousemove listener in componentDidUpdate
  */
  resizeFunc(evt) {
    this.div = document.getElementById('timeline-div');
    this.div.style.height = `${this.div.getBoundingClientRect().height + evt.movementY}px`;
  }

  /* This is the callback method for the mouseup listener in componentDidUpdate
  */
  mouseUp() {
    const { map } = this.props;
    document.removeEventListener('mousemove', this.resizeFunc, false);
    const mapDiv = document.getElementById('model-map');
    const div = document.getElementById('timeline-div');
    const newMapSize = div.getBoundingClientRect().height + 65;
    mapDiv.style.top = `${newMapSize}px`;
    map.updateSize();
  }

  /*  This method is triggered when the searchbar input changes, and it update the
  component state to have all of the results that start with the input.
  */
  handleSearch(search) {
    let tempList = [];
    this.searchLength = search.length;
    this.setState({ searchResults: tempList });
    const div = document.getElementById('timeline-div');
    div.style.height = `${div.getBoundingClientRect().height + 1}px`;
    div.style.height = `${div.getBoundingClientRect().height - 1}px`;
    if (search.length > 0) {
      tempList = this.sortedEnts.filter(
        (name) => name.toLowerCase().startsWith(search.toLowerCase()),
      );
      this.setState({ searchResults: tempList });
    }
  }

  /* This method listens for clicks on search results, and adds an item to the graph
  */
  makeSearchResultsClickable(results) {
    const { dispatch } = this.props;
    results.forEach((name) => {
      const searchItem = document.getElementById(formatID(`timeline-${name}`));
      searchItem.onclick = () => {
        // var spatialElement = this.getSpatialItemByName(name);
        // var nonSpatialElement = this.getNonSpatialItemByName(name);
        const entity = this.getEntByName(name);
        if (entity && !this.duplicateGraphItem(name)) {
          dispatch(addToGraphList(entity));
        }
      };
    });
  }

  /* This method is used to add entities specified in modelInfo when
     new case is rendered (called in componentDidMount)
  */
  addPreloadedEntities() {
    const { dispatch } = this.props;
    this.preloadedEntities.forEach((name) => {
      const entity = this.getEntByName(name);
      if (entity && !this.duplicateGraphItem(name)) {
        dispatch(addToGraphList(entity));
      }
    });
  }

  /* This method makes sure an item isnt added to the graph twice
    */
  duplicateGraphItem(name) {
    const { graphList } = this.props;
    let duplicate = false;
    duplicate = Object.keys(graphList).some((key) => {
      if (graphList[key].name === name) {
        return true;
      }
      return false;
    });
    return duplicate;
  }


  // compare search results alphabetically by name
  compareNames(a, b) {
    const { nameSortedAsc } = this.state;
    const aName = a.toLowerCase();
    const bName = b.toLowerCase();

    // find index of suffix (for example, suffix of 'Entity14' is '14')
    const suffixRegex = /[0-9]+$/;
    const aSuffixInd = aName.search(suffixRegex);
    const bSuffixInd = bName.search(suffixRegex);

    let aPrefix; let bPrefix; let aSuffix; let
      bSuffix;
    if (aSuffixInd !== -1) {
      // separate root name and suffix
      aPrefix = aName.slice(0, aSuffixInd);
      aSuffix = parseInt(aName.slice(aSuffixInd), 10);
    } else {
      // entity name does not include numerical suffix
      aPrefix = aName;
      aSuffix = -1;
    }
    if (bSuffixInd !== -1) {
      // separate root name and suffix
      bPrefix = bName.slice(0, bSuffixInd);
      bSuffix = parseInt(bName.slice(bSuffixInd), 10);
    } else {
      // entity name does not include numerical suffix
      bPrefix = bName;
      bSuffix = -1;
    }

    if (aPrefix < bPrefix) {
      // if ascending order next, a comes first; otherwise b comes first
      return !nameSortedAsc ? -1 : 1;
    }
    if (aPrefix > bPrefix) {
      // if ascending order next, b comes first; otherwise a comes first
      return !nameSortedAsc ? 1 : -1;
    }
    // root names are the same
    // if ascending order next, name with lower suffix ordered first; otherwise opposite order
    return !nameSortedAsc ? (aSuffix - bSuffix) : (bSuffix - aSuffix);
  }

  // compare search results by state change time
  compareTimes(a, b) {
    const { nonSpatialEnts, spatialEnts } = this.props;
    const { timeSortedAsc } = this.state;
    const aProps = a in nonSpatialEnts
      ? nonSpatialEnts[a]
      : spatialEnts[a];
    let aTime = aProps.ChangeTime;
    const aState = aProps.ImmediateEffects;

    const bProps = b in nonSpatialEnts
      ? nonSpatialEnts[b]
      : spatialEnts[b];
    let bTime = bProps.ChangeTime;
    const bState = bProps.ImmediateEffects;

    /* assume for now order the entities without a time value and a state of 1 first,
     * then entities with a time value (relative to each other based on the value),
     * and then entities without a time value and a state of 0 last
     * check for Dave's response in issue #68
     */
    if (!('ChangeTime' in aProps)) {
      aTime = aState === 1 ? 0 : Number.MAX_VALUE;
    }
    if (!('ChangeTime' in bProps)) {
      bTime = bState === 1 ? 0 : Number.MAX_VALUE;
    }
    // if ascending order next, lower time value ordered first; otherwise opposite order
    return !timeSortedAsc ? (aTime - bTime) : (bTime - aTime);
  }

  sortEntTable(compareFunc) {
    const { searchResults } = this.state;
    // copy values
    const entsListCopy = [...this.sortedEnts];
    const resultsCopy = [...searchResults];

    // sort ents list (future search results will be filtered from this list)
    entsListCopy.sort(compareFunc);
    if (this.searchLength > 0) {
      // also sort current search results if there are any
      resultsCopy.sort(compareFunc);
    }
    return { sortedEnts: entsListCopy, searchResults: resultsCopy };
  }

  sortByName() {
    // This moves the scroll position back to the top of the table when you change sorting
    const timelineTable = document.getElementById('search-results-container');
    timelineTable.scrollTop = 0;
    const { nameSortedAsc } = this.state;
    const stateUpdates = this.sortEntTable(this.compareNames);
    stateUpdates.nameSortedAsc = !nameSortedAsc;
    stateUpdates.timeSortedAsc = false;
    this.setState(stateUpdates);
    this.sortedEnts = stateUpdates.sortedEnts;
  }

  sortByTime() {
    // This moves the scroll position back to the top of the table when you change sorting
    const timelineTable = document.getElementById('search-results-container');
    timelineTable.scrollTop = 0;
    const { timeSortedAsc } = this.state;
    const stateUpdates = this.sortEntTable(this.compareTimes);
    stateUpdates.timeSortedAsc = !timeSortedAsc;
    stateUpdates.nameSortedAsc = false;
    this.setState(stateUpdates);
    this.sortedEnts = stateUpdates.sortedEnts;
  }

  hideSearchPanel() {
    this.setState({ searchPanelShow: false });
  }

  showSearchPanel() {
    this.setState({ searchPanelShow: true });
  }

  render() {
    const {
      nonSpatialEnts, spatialEnts, timelineExpanded, maxTime,
    } = this.props;
    const {
      searchResults, searchPanelShow,
    } = this.state;
    const showResult = (item) => {
      const entProps = item in nonSpatialEnts
        ? nonSpatialEnts[item]
        : spatialEnts[item];
      let time;
      if (entProps) {
        if ('ChangeTime' in entProps) {
          time = entProps.ChangeTime === 0 ? 0 : entProps.ChangeTime.toFixed(3);
        } else if (entProps.ImmediateEffects === 1) {
          time = 0;
        } else {
          time = '';
        }
      }

      return (
        <React.Fragment key={item}>
          <tr id={formatID(`timeline-${item}`)} style={{ cursor: 'pointer' }}>
            <td title={item} data-testid="search-result-name" className="search-result-col">
              {item}
            </td>
            <td data-testid="search-result-time" className="search-result-col">
              {time}
            </td>
          </tr>
        </React.Fragment>
      );
    };

    return (
      <>
        {timelineExpanded
          && (
          <div id="timeline-div" className={timelineExpanded ? 'expanded-timeline' : 'expanded-timeline hide-timeline'}>
            <div className="resizer bottom" data-testid="resizer-bottom" />
            <span className={searchPanelShow ? 'hide' : ''}>
              <Button
                className="search-expand-button"
                data-testid="search-expand-button-id"
                variant="outline-dark"
                onClick={this.showSearchPanel}
              >
                <FontAwesomeIcon icon={faAngleRight} size="lg" />
              </Button>
            </span>
            <div className={searchPanelShow ? 'search-group' : 'hide'} id="search-group-panel" data-testid="search-group-panel">
              <div>
                <SearchField
                  placeholder="Search..."
                  onChange={this.handleSearch}
                  classNames="search-bar"
                />
                <Button
                  className="search-collapse-button"
                  data-testid="search-collapse-button-id"
                  variant="outline-dark"
                  onClick={this.hideSearchPanel}
                >
                  <FontAwesomeIcon icon={faAngleLeft} size="lg" />
                </Button>
              </div>
              <div className="search-results" id="search-results-container">
                {searchResults.length === 0 && this.searchLength > 0
                  ? <p style={{ textAlign: 'center', color: '#888' }}>No results found</p>
                  : (
                    <table id="search-results-table" data-testid="search-results-table">
                      <tbody>
                        <tr>
                          <th className="search-table-header">
                            Name
                            <Button variant="light" data-testid="sort-name" onClick={this.sortByName}>
                              <FontAwesomeIcon icon={faSort} />
                            </Button>
                          </th>
                          <th className="search-table-header">
                            Time
                            <Button variant="light" data-testid="sort-time" onClick={this.sortByTime}>
                              <FontAwesomeIcon icon={faSort} />
                            </Button>
                          </th>
                        </tr>
                        {this.searchLength === 0
                          ? this.sortedEnts.map((ent) => showResult(ent)) // show all entities
                          // show search results
                          : searchResults.map((item) => showResult(item))}
                      </tbody>
                    </table>
                  )}
              </div>

            </div>
            <TimelineGraph
              max={maxTime}
              duplicateGraphItem={this.duplicateGraphItem}
              searchPanelShow={searchPanelShow}
            />
          </div>
          )}
      </>
    );
  }
}

ExpandedTimeline.propTypes = {
  dispatch: PropTypes.func.isRequired,
  timelineExpanded: PropTypes.bool.isRequired,
  map: mapType,
  selectedCaseId: PropTypes.number,
  casesById: casesByIdType,
  nonSpatialEnts: nonSpatialEntsType,
  spatialEnts: spatialEntsType,
  allEntities: PropTypes.arrayOf(PropTypes.string),
  maxTime: PropTypes.number.isRequired,
  graphList: graphListType,
};

ExpandedTimeline.defaultProps = {
  allEntities: [],
  nonSpatialEnts: {},
  spatialEnts: {},
};

const mapStateToProps = (state) => ({
  map: state.map,
  timelineExpanded: state.timelineExpanded,
  selectedCaseId: state.selectedCaseId,
  casesById: state.casesById,
  graphList: state.graphList,
});

export default connect(mapStateToProps)(ExpandedTimeline);
