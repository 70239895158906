import React from 'react';
import PropTypes from 'prop-types';
import ReactBootstrapSlider from 'react-bootstrap-slider';
import 'bootstrap-slider/dist/css/bootstrap-slider.css';
import LayerGroup from 'ol/layer/Group';
import { connect } from 'react-redux';
import {
  Button, ButtonGroup, Row, Col,
} from 'react-bootstrap';
import Style from 'ol/style/Style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlay, faPause, faForward, faBackward, faFastBackward, faAngleDown, faAngleUp,
} from '@fortawesome/free-solid-svg-icons';
import { setSliderTime, toggleExpandedTimeline } from '../actions';
import { mapType, casesByIdType, modelsByIdType } from '../types';

class TimeSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timelineEnabled: false,
      min: 0,
      timer: 0,
      displayedTime: 0,
    };
    this.incrementSlider = this.incrementSlider.bind(this);
    this.decrementSlider = this.decrementSlider.bind(this);
    this.buttonStatus = this.buttonStatus.bind(this);
    this.timeDisplay = this.timeDisplay.bind(this);
    this.runTimeline = this.runTimeline.bind(this);
    this.resetTimeline = this.resetTimeline.bind(this);
    this.updateEntities = this.updateEntities.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.dragging = this.dragging.bind(this);
  }

  // LIFECYCLE METHODS
  componentDidMount() {
    const { time } = this.props;
    this.setState({ displayedTime: time });
  }
  
  componentWillUnmount() {
    // stop timeline slider
    clearInterval(this.myInterval);
  }

  componentDidUpdate(prevProps) {
    const { selectedCaseId } = this.props;
    if (selectedCaseId !== prevProps.selectedCaseId) {
      this.resetTimeline();
    }
  }

  /*  This method increments the value on the slider, can be combined with
      run timeline IF we remove the forward button.
  */
  incrementSlider() {
    const {
      casesById, time, selectedCaseId, dispatch,
    } = this.props;
    const max = Math.ceil(casesById[selectedCaseId].max_time);
    if (time < max && selectedCaseId !== -1) {
      this.updateEntities(time + 1);
      this.setState({ displayedTime: time + 1 });
      dispatch(setSliderTime(time + 1));
    }
  }

  /*  This method decrements the value on the slider
  */
  decrementSlider() {
    const { time, selectedCaseId, dispatch } = this.props;
    if (time > 0 && selectedCaseId !== -1) {
      this.updateEntities(time - 1);
      this.setState({ displayedTime: time - 1 });
      dispatch(setSliderTime(time - 1));
    }
  }

  /*  This method disables the start button if the slider is maxed out or if
      a case isn't selected
  */
  buttonStatus() {
    const { selectedCaseId } = this.props;
    if (selectedCaseId === -1) {
      return 'disabled';
    }
    return '';
  }

  /*  This method sets all features back to their "immediateEffects" value,
      resets the sliderTime to 0, and resets the timeline timer and boolean
  */
  resetTimeline() {
    const { dispatch } = this.props;
    this.updateEntities(0);
    dispatch(setSliderTime(0));
    this.setState({ timelineEnabled: false });
    this.setState({ timer: 0 });
    this.setState({ displayedTime: 0 });
    clearInterval(this.myInterval);
  }

  /*  This method toggles Start/Stop on The Timeline
  */
  runTimeline() {
    const { time, casesById, selectedCaseId } = this.props;
    const { timelineEnabled } = this.state;
    const max = Math.ceil(casesById[selectedCaseId].max_time);
    // If timeline started, pause it
    if (timelineEnabled) {
      this.setState({ timelineEnabled: false });
      clearInterval(this.myInterval);
    } else {
      this.setState({ timelineEnabled: true });
      this.myInterval = setInterval(() => {
        const { timer } = this.state;
        this.setState({ timer: timer + 1 });
        // The interval function is called every 10th of a second, timer % 10 = every 1 second
        if (timer % 10 === 0) {
          this.incrementSlider();
        }
        if (time === max) {
          clearInterval(this.myInterval);
          this.setState({ timelineEnabled: false });
        }
      }, 100);
    }
  }

  /* Helper method to Display the units of time on slider
  */
  timeDisplay() {
    const { selectedModelId, modelsById } = this.props;
    const { displayedTime } = this.state;
    let formattedTime = 'No model selected';
    if (selectedModelId !== -1) {
      switch (modelsById[0].time_unit) {
        case 'days':
          formattedTime = `Day: ${displayedTime}`;
          break;
        case 'hours':
          formattedTime = `Hour: ${displayedTime}`;
          break;
        case 'weeks':
          formattedTime = `Week: ${displayedTime}`;
          break;
        default:
          formattedTime = `Time: ${displayedTime}`;
          break;
      }
    }
    return formattedTime;
  }

  /*  This method checks for changes in each feature and if that feature's
      change time is less than current, set it red otherwise set it green
  */
  updateEntities(time) {
    const { map, operationalShowing, offlineShowing } = this.props;
    const layers = map.getLayers().getArray();
    layers.forEach((layer) => {
      if (layer instanceof LayerGroup && layer.get('title') === 'Entities (Spatial)') {
        const vectorLayers = layer.getProperties().layers.getArray();
        vectorLayers.forEach((vectorLayer) => {
          const features = vectorLayer.getSource().getFeatures();
          features.forEach((feature) => {
            if (time >= feature.get('ChangeTime')) {
              feature.set('EntityState', 1);
              if (operationalShowing === true) {
                // this shows feature
                feature.setStyle(null);
              } else {
                // this hides it
                feature.setStyle(new Style({}));
              }
            } else if (time < feature.get('ChangeTime')) {
              feature.set('EntityState', 0);
              if (offlineShowing === true) {
                // this shows feature
                feature.setStyle(null);
              } else {
                // this hides it
                feature.setStyle(new Style({}));
              }
            }
          }, this);
        }, this);
      }
    }, this);
  }

  /*  This method allows the slider to change the value of the slider in the
      redux store when the user changes the slider AND releases the mouse
  */
  changeValue(event) {
    const { dispatch } = this.props;
    const day = event.target.value;
    dispatch(setSliderTime(day));
    this.updateEntities(day);
  }

  /*  This method allows the slider to change the value of the time display
      above the slider while the user is dragging the slider.
  */
  dragging(event) {
    this.setState({ displayedTime: event.target.value });
  }

  /*  This method allows the down/up arrow button to open/close the expanded
      timeline
  */
  toggleExpandedTimeline() {
    const { dispatch, timelineExpanded } = this.props;
    if (timelineExpanded) {
      dispatch(toggleExpandedTimeline(false));
    } else {
      dispatch(toggleExpandedTimeline(true));
    }
  }

  render() {
    const { casesById, selectedCaseId, timelineExpanded } = this.props;
    const { timelineEnabled, displayedTime, min } = this.state;
    let max = 0;
    if (selectedCaseId !== -1) {
      max = Math.ceil(casesById[selectedCaseId].max_time);
    }
    return (
      <>
        <Row style={{ marginRight: '10px' }}>
          <Col>
            <h6 style={{ textAlign: 'center', marginBottom: '-4px', fontSize: '14px' }}>{this.timeDisplay()}</h6>
            <ReactBootstrapSlider
              value={displayedTime}
              slideStop={this.changeValue}
              change={this.dragging}
              step={1}
              id="time-slider"
              max={max}
              min={min}
              disabled={this.buttonStatus()}
              tooltip="always"
            />
          </Col>
          <ButtonGroup size="md" style={{ height: '37px' }}>
            <Button
              data-testid="reset"
              disabled={this.buttonStatus()}
              variant="outline-dark"
              onClick={() => this.resetTimeline()}
            >
              {' '}
              <FontAwesomeIcon icon={faFastBackward} size="xs" />
            </Button>
            <Button data-testid="back" id="back" disabled={this.buttonStatus()} variant="outline-dark" onClick={() => this.decrementSlider()}>
              <FontAwesomeIcon icon={faBackward} size="xs" />
            </Button>
            <Button data-testid="play" disabled={this.buttonStatus()} variant="outline-dark" onClick={() => this.runTimeline()}>
              {timelineEnabled ? <FontAwesomeIcon icon={faPause} size="xs" /> : <FontAwesomeIcon icon={faPlay} size="xs" />}
            </Button>
            <Button data-testid="forward" id="forward" disabled={this.buttonStatus()} variant="outline-dark" onClick={() => this.incrementSlider()}>
              <FontAwesomeIcon icon={faForward} size="xs" />
            </Button>

            <Button
              id="expand"
              data-testid="expand"
              disabled={this.buttonStatus()}
              variant="outline-dark"
              onClick={() => this.toggleExpandedTimeline()}
            >
              {timelineExpanded ? <FontAwesomeIcon icon={faAngleUp} size="lg" /> : <FontAwesomeIcon icon={faAngleDown} size="lg" />}
            </Button>

          </ButtonGroup>
        </Row>
      </>
    );
  }
}

TimeSlider.propTypes = {
  casesById: casesByIdType,
  dispatch: PropTypes.elementType.isRequired,
  map: mapType,
  modelsById: modelsByIdType,
  selectedCaseId: PropTypes.number,
  selectedModelId: PropTypes.number,
  time: PropTypes.number,
  timelineExpanded: PropTypes.bool,
  operationalShowing: PropTypes.bool,
  offlineShowing: PropTypes.bool,

};

const mapStateToProps = (state) => ({
  map: state.map,
  selectedCaseId: state.selectedCaseId,
  selectedModelId: state.selectedModelId,
  modelsById: state.modelsById,
  operationalShowing: state.operationalShowing,
  offlineShowing: state.offlineShowing,
  time: state.sliderTime,
  casesById: state.casesById,
  timelineExpanded: state.timelineExpanded,
});


export default connect(mapStateToProps)(TimeSlider);
