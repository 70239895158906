import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import '../App.css';
import SingleFeaturePopup from './SingleFeaturePopup';
import MultiFeaturePopup from './MultiFeaturePopup';
import { setHighestZIndex, setInteractions, removeFromPopupList } from '../actions';
import getEntityFeature from '../utils/getEntityFeature';
import getFeatureLayer from '../utils/getFeatureLayer';
import {
  ModelInfoType, mapType, interactionsType, popupInfoType, spatialEntsType, nonSpatialEntsType,
} from '../types';


class InteractivePopupShell extends React.Component {
  constructor(props) {
    super(props);
    const { popupInfo } = this.props;
    this.popupRef = React.createRef();
    this.closeRef = React.createRef();
    this.closeId = '';
    this.lastPopupPosition = [];
    this.currentPopupPosition = [];
    this.popupPosition = popupInfo.popupCoords;
    this.touchMovement = [];
    this.type = popupInfo.feature.length > 1 ? 'multi' : 'single';

    this.mouseDown = this.mouseDown.bind(this);
    this.resizeFunc = this.resizeFunc.bind(this);
    this.dragFunc = this.dragFunc.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
    this.featureIsHighlighted = this.featureIsHighlighted.bind(this);
    this.removeHighlightFromFeature = this.removeHighlightFromFeature.bind(this);
    this.highlightFeature = this.highlightFeature.bind(this);
    this.clearInteractions = this.clearInteractions.bind(this);
  }

  // LIFECYCLE METHODS

  componentDidMount() {
    const { highestZIndex, popupInfo, dispatch } = this.props;
    const div = this.popupRef.current;
    const infoId = popupInfo.popupId;
    // Set popup position from overlay
    // var map = this.props.map;
    // var popup = map.getOverlayById(infoId);
    // this.popupPosition = map.getPixelFromCoordinate(popup.getPosition());
    // popup.setPosition(map.getCoordinateFromPixel(this.popupPosition));

    div.addEventListener('mousedown', this.mouseDown);
    document.addEventListener('mouseup', this.mouseUp);

    div.addEventListener('touchstart', this.mouseDown);
    document.addEventListener('touchend', this.mouseUp);

    this.makePopupInteractions(infoId, popupInfo.feature);
    div.style.zIndex = highestZIndex;
    // remember the highest Z index
    dispatch(setHighestZIndex(highestZIndex + 1));
  }

  componentWillUnmount() {
    const { popupInfo, dispatch } = this.props;
    const div = this.popupRef.current;
    // Remove all event listeners when component is unmounted
    div.removeEventListener('mousedown', this.mouseDown);
    document.removeEventListener('mouseup', this.mouseUp);
    div.removeEventListener('touchstart', this.mouseDown);
    document.removeEventListener('touchend', this.mouseUp);
    document.removeEventListener('mousemove', this.dragFunc, false);
    document.removeEventListener('mousemove', this.resizeFunc, false);
    // Remove touch move listener
    document.removeEventListener('touchmove', this.dragFunc, false);
    const { map } = this.props;
    const overlay = map.getOverlayById(popupInfo.popupId);
    map.removeOverlay(overlay);
    dispatch(removeFromPopupList(popupInfo));
  }

  /*  This method adds the x button to popups.
  */
  makePopupInteractions(infoId, feature) {
    const { dispatch } = this.props;
    const popupCloser = this.closeRef.current;
    // Regular Popup
    this.closeId = `x${feature[0].get('Name').replace(/[^0-9a-z]/gi, '')}`;
    popupCloser.setAttribute('id', this.closeId);
    popupCloser.onclick = () => {
      const { map, popupInfo } = this.props;
      const overlay = map.getOverlayById(infoId);
      // If feature is highlighted when closing, unhighlight it
      feature.forEach((feat) => {
        if (this.featureIsHighlighted(feat)) {
          this.removeHighlightFromFeature(feat);
        }
      });
      dispatch(removeFromPopupList(popupInfo));
      map.removeOverlay(overlay);
      return false;
    };
  }

  /*  This method is a listener for a mouseDown event
      to handle drag or resize popups.
  */

  mouseDown(evt) {
    const {
      dispatch, popupInfo, highestZIndex,
    } = this.props;
    const div = document.getElementById(`shell-${popupInfo.popupId}`);
    // This deals with highlighting the feature indicated by the popup box
    //    when clicked on
    // left click only, and don't highlight when closing popup
    if (evt.button === 0 && evt.target !== this.closeRef.current) {
      // clicked on popup comes to front
      div.style.zIndex = highestZIndex;
      // remember the highest Z index
      dispatch(setHighestZIndex(highestZIndex + 1));
      // Resizing
      const classList = ['resizer top-right', 'resizer bottom-right', 'resizer bottom-left', 'resizer top-left'];
      const prohibitedTags = ['svg', 'path'];
      if (classList.includes(evt.target.className)) {
        this.resizeFunc.type = evt.target.className;
        document.addEventListener('mousemove', this.resizeFunc, false);
        // document.addEventListener('touchmove', this.resizeFunc, false);

      // Dragging
      // We dont want to drag on the popup icon or dependency text.
      } else if (evt.target.className !== 'popup-text' && !prohibitedTags.includes(evt.target.tagName)) {
        document.addEventListener('mousemove', this.dragFunc, false);
        // document.addEventListener("touchmove", this.dragFunc, false);
      }

    // If touchscreen event -- NOTE with some dev inspect tools open this could
    // apperently be triggered on desktop -- something to keep in mind
    } else if (evt.type === 'touchstart') {
      // Only add drag listener if touch is on top quarter of the popup
      if (evt.pageY - (div.getBoundingClientRect().height / 4) < div.getBoundingClientRect().top) {
        document.addEventListener('touchmove', this.dragFunc, false);
      }
    }
    return false;
  }

  /*  This method is a listener for a mouseMove event
      when dragging on corners to resize popups.
  */
  resizeFunc(evt) {
    // evt.preventDefault();
    const { map, popupInfo } = this.props;
    const infoId = popupInfo.popupId;
    const popup = map.getOverlayById(infoId);
    const div = document.getElementById(`shell-${popupInfo.popupId}`);
    const mapContainer = document.getElementById('model-map');
    // If the div doesn't exist resizing isn't possible
    if (div) {
      const overlayContainer = div.parentNode;
      // All the numbers after width/height are because the popup gets offset when displayed
      // on the map in order to be shown positioned above the feature.
      const minWidth = 300;
      const minHeight = 160;
      const adjustedWidth = div.getBoundingClientRect().width + 3;
      const adjustedHeight = div.getBoundingClientRect().height + 5;
      const calculatedWidthRight = adjustedWidth
        + (evt.clientX - div.getBoundingClientRect().right);
      const calculatedWidthLeft = adjustedWidth
        - (evt.clientX - div.getBoundingClientRect().left);
      const calculatedHeightBottom = adjustedHeight
        + (evt.clientY - div.getBoundingClientRect().bottom);
      const calculatedHeightTop = adjustedHeight
        - (evt.clientY - div.getBoundingClientRect().top);
      const containerTop = overlayContainer.getBoundingClientRect().top
        - (mapContainer.getBoundingClientRect().top - 5)
        + (evt.clientY - div.getBoundingClientRect().bottom);
      const containerLeft = overlayContainer.getBoundingClientRect().left
        + 47
        + (evt.clientX - overlayContainer.getBoundingClientRect().left);
      try {
        this.popupPosition = map.getPixelFromCoordinate(popup.getPosition());
        if (this.resizeFunc.type === 'resizer top-right') {
          // x
          div.style.width = `${calculatedWidthRight}px`;
          // y
          div.style.height = `${calculatedHeightTop}px`;
        } else if (this.resizeFunc.type === 'resizer bottom-right') {
          // x
          div.style.width = `${calculatedWidthRight}px`;
          // y
          div.style.height = `${calculatedHeightBottom}px`;
          if (calculatedHeightBottom >= minHeight) {
            this.popupPosition[1] = containerTop;
          }
        } else if (this.resizeFunc.type === 'resizer bottom-left') {
          // x
          div.style.width = `${calculatedWidthLeft}px`;
          if (calculatedWidthLeft >= minWidth) {
            this.popupPosition[0] = containerLeft;
          }
          // y
          div.style.height = `${calculatedHeightBottom}px`;
          if (calculatedHeightBottom >= minHeight) {
            this.popupPosition[1] = containerTop;
          }
        } else if (this.resizeFunc.type === 'resizer top-left') {
          // x
          div.style.width = `${calculatedWidthLeft}px`;
          if (calculatedWidthLeft >= minWidth) {
            this.popupPosition[0] = containerLeft;
          }
          // y
          div.style.height = `${calculatedHeightTop}px`;
        }
        popup.setPosition(map.getCoordinateFromPixel(this.popupPosition));
      } catch (err) {
        // Popup closed so nothing to drag
        // this.setState({ mouseDown: false });
      }
    }
    return false;
  }

  /*  This method is a listener for a mouseMove event
      for making popup follow the cursor when dragging (not on corners).
  */
  dragFunc(evt) {
    const { map, popupInfo } = this.props;
    const div = document.getElementById(`shell-${popupInfo.popupId}`);
    const infoId = popupInfo.popupId;

    const mapContainer = document.getElementById('model-map');
    const mapY = mapContainer.getBoundingClientRect().top;

    // if (this.state.mouseDown === true) {
    // This is so mousemove doesnt affect every open popup box (since mousemove
    // is document-wide now)
    // if (divId === this.state.currentDraggable) {
    const popup = map.getOverlayById(infoId);
    // If touch gesture


    // handles edgecase where popup is dragged while closed?
    if (popup && div) {
      this.popupPosition = map.getPixelFromCoordinate(popup.getPosition());
      if (evt.type === 'touchmove') {
        const infoDiv = document.getElementById(infoId);
        this.currentPopupPosition = { x: evt.pageX, y: evt.pageY };
        if (this.lastPopupPosition.x === undefined) {
          this.lastPopupPosition = this.currentPopupPosition;
        }
        const movementX = this.currentPopupPosition.x - this.lastPopupPosition.x;
        const movementY = this.currentPopupPosition.y - this.lastPopupPosition.y;
        const scrollpos = infoDiv.scrollTop + movementY;
        this.popupPosition[0] += movementX;
        this.popupPosition[1] += movementY;
        popup.setPosition(map.getCoordinateFromPixel(this.popupPosition));
        // This ignores scrolling when dragging popup with finger
        infoDiv.scrollTop = scrollpos;
        this.lastPopupPosition = this.currentPopupPosition;
      } else {
        // Don't let popup leave screen
        if (evt.clientX > 0 && evt.clientX < window.innerWidth - 10) {
          this.popupPosition[0] += evt.movementX;

        // else if moving towards edge too fast to keep up with, just move popup to edge
        } else if (evt.clientX + evt.movementX > window.innerWidth - 10) {
          this.popupPosition[0] = window.innerWidth;
        } else if (evt.clientX + evt.movementX < 0) {
          this.popupPosition[0] = 0;
        }
        // 65 because of top nav height
        if (evt.clientY > mapY && evt.clientY < window.innerHeight - 10) {
          this.popupPosition[1] += evt.movementY;

        // else if moving towards edge too fast to keep up with, just move popup to edge
        } else if (evt.clientY + evt.movementY < 0) {
          this.popupPosition[1] = 0;
        } else if (evt.clientY + evt.movementY > window.innerHeight - 10) {
          this.popupPosition[1] = window.innerHeight;
        }
        // div.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
        // }
        // }
        popup.setPosition(map.getCoordinateFromPixel(this.popupPosition));
      }
    }
    return false;
  }

  /*  This method creates a Document wide listener for
      when mouseclick is lifted up (released)
  */
  mouseUp() {
    document.removeEventListener('mousemove', this.dragFunc, false);
    document.removeEventListener('mousemove', this.resizeFunc, false);

    // Remove touch move listener
    document.removeEventListener('touchmove', this.dragFunc, false);
    this.touchMovement = [];
    this.currentPopupPosition = [];
    this.lastPopupPosition = [];
  }

  /*  This method handles highlighting features when a popup is clicked on.
  */
  highlightFeature(feature) {
    const { interactions, dispatch, map } = this.props;
    const newInteractions = interactions;
    const selectedFeatures = newInteractions.getFeatures();
    if (feature && !this.featureIsHighlighted(feature)) {
      const layer = getFeatureLayer(map, feature);
      // if the layer is visible, highlight
      if (layer.get('visible')) {
        selectedFeatures.push(feature);
      }
    }

    dispatch(setInteractions(newInteractions));
  }

  /* Helper function to clear all interactions before highlighting new feature
  The reason it isn't called in highlightFeature is because MultiFeaturePopup
  calls highlightFeature in a loop to highlight all of it's features */
  clearInteractions() {
    const { interactions, dispatch } = this.props;
    const newInteractions = interactions;
    const selectedFeatures = newInteractions.getFeatures();
    selectedFeatures.clear();
    dispatch(setInteractions(newInteractions));
  }

  removeHighlightFromFeature(feature) {
    const { interactions, dispatch } = this.props;
    for (const i in interactions.getFeatures().array_) {
      if (interactions.getFeatures().array_[i] === feature) {
        interactions.getFeatures().remove(feature);
      }
    }
    dispatch(setInteractions(interactions));
  }

  featureIsHighlighted(feature) {
    const { interactions } = this.props;
    for (const i in interactions.getFeatures().array_) {
      if (interactions.getFeatures().array_[i] === feature) {
        return true;
      }
    }
    return false;
  }


  render() {
    const {
      popupInfo, ModelInfo, map, spatialEnts, nonSpatialEnts,
    } = this.props;
    const singlePopupFeature = popupInfo.feature[0];
    const singlePopupName = singlePopupFeature.get('Name');
    const singlePopupLyr = singlePopupFeature.get('LayerGroup');
    return (
      <div data-testid={`shell-${popupInfo.popupId}`}>
        <div
          className="ol-popup shell"
          ref={this.popupRef}
          id={`shell-${popupInfo.popupId}`}
        >
          <div
            ref={this.closeRef}
            className="ol-popup-closer"
          >
            <FontAwesomeIcon icon={faTimes} pull="right" />
          </div>
          <div className="resizer top-right" />
          <div className="resizer bottom-right" />
          <div className="resizer bottom-left" />
          <div className="resizer top-left" />
          {this.type === 'single'
            ? (
              <SingleFeaturePopup

                popupInfo={popupInfo}
                popupCoords={this.popupPosition}
                feature={
                  getEntityFeature(
                    singlePopupName,
                    spatialEnts,
                    nonSpatialEnts,
                    singlePopupLyr,
                    map,
                  )
                }
                ModelInfo={ModelInfo}
                popupId={popupInfo.popupId}
                highlightFeature={this.highlightFeature}
                removeHighlightFromFeature={this.removeHighlightFromFeature}
                featureIsHighlighted={this.featureIsHighlighted}
                clearInteractions={this.clearInteractions}
              />
            )
            : (
              <MultiFeaturePopup
                popupInfo={popupInfo}
                popupCoords={this.popupPosition}
                popupId={popupInfo.popupId}
                features={popupInfo.feature}
                highlightFeature={this.highlightFeature}
                clearInteractions={this.clearInteractions}
              />
            )}
        </div>
      </div>
    );
  }
}
InteractivePopupShell.propTypes = {
  dispatch: PropTypes.elementType.isRequired,
  highestZIndex: PropTypes.number.isRequired,
  interactions: interactionsType,
  map: mapType,
  ModelInfo: ModelInfoType,
  popupInfo: popupInfoType,
  spatialEnts: spatialEntsType,
  nonSpatialEnts: nonSpatialEntsType,
};

const mapStateToProps = (state) => ({
  map: state.map,
  time: state.sliderTime,
  selectedCaseId: state.selectedCaseId,
  highestZIndex: state.highestZIndex,
  interactions: state.interactions,
  popupList: state.popupList,
  spatialEnts: state.spatialEnts,
  nonSpatialEnts: state.nonSpatialEnts,
});

export default connect(mapStateToProps)(InteractivePopupShell);
