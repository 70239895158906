import { connect } from 'react-redux';
import { selectModel, selectScenario, selectCase, clearGraphList, clearPopupList } from '../actions';
import ElementSelect from '../components/ElementSelect';

// helper function to convert string select value to int
function strToInt(str) {
  return parseInt(str, 10);
}

// returns true if model is pre-installed, false otherwise
function isPreInst(model) {
  return model.type === 'pre-inst';
}

// returns the id of the selected model if one has been selected
// and it is a pre-installed model, returns -1 otherwise
function selectedPreInst(state) {
  const selectedId = state.selectedModelId;
  const modelsMap = state.modelsById;
  const selected = selectedId in modelsMap ? modelsMap[selectedId] : undefined;
  return (selected && isPreInst(selected)) ? selectedId : -1;
}

// returns the list of scenarios/cases ids corresponding to the selected
// model/scenario
function elemsOfSelected(selectedId, mapId, isModel) {
  if (selectedId === -1) {
    return [];
  }

  return isModel ? mapId[selectedId].scenarios : mapId[selectedId].cases;
}

const mapStateToProps = (state, ownProps) => {
  switch (ownProps.elemType) {
    case 'model':
      return {
        elements: state.modelIds.filter((id) => isPreInst(state.modelsById[id])),
        elementsById: state.modelsById,
        selectedElement: selectedPreInst(state),
        caseType: ownProps.elemType,
      };
    case 'scenario':
      return {
        elements: elemsOfSelected(state.selectedModelId, state.modelsById, true),
        elementsById: state.scensById,
        selectedElement: state.selectedScenId,
        caseType: ownProps.elemType,
      };
    default:
      return {
        elements: elemsOfSelected(state.selectedScenId, state.scensById, false),
        elementsById: state.casesById,
        selectedElement: state.selectedCaseId,
        caseType: ownProps.elemType,
      };
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  switch (ownProps.elemType) {
    case 'model':
      return {
        onSelect: (id) => {
          dispatch(selectModel(strToInt(id)));
          dispatch(selectScenario(-1));
          dispatch(selectCase(-1));
        },
      };
    case 'scenario':
      return {
        onSelect: (id) => {
          dispatch(selectScenario(strToInt(id)));
          dispatch(selectCase(-1));
        },
      };
    default:
      return {
        onSelect: (id) => {
          dispatch(selectCase(strToInt(id)));
          dispatch(clearGraphList()); // clear loaded timeline ens list for new case
          dispatch(clearPopupList()); // clear open popups list for new case
        },
      };
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ElementSelect);
