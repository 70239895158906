import React from 'react';
import { legendInfoType } from '../types';
import { offlineColorCustomOpacity, operationalColorCustomOpacity } from '../utils/colors';
import LegendGroup from './LegendGroup';


function Legend(props) {
  const title = 'Entities';
  const baseItem = {
    Entities: {
      Operational: {
        Color: operationalColorCustomOpacity(0.6),
      },
      'Non-Operational': {
        Color: offlineColorCustomOpacity(0.6),
      },
    },
  };
  const { legendInfo, supLayersVisibility } = props;
  return (
    <div data-testid="legend-component" id="legend-component" className="ol-unselectable map-app-legend">
      <div className="map-app-legend-title">
        Legend
      </div>
      {/* Entities section */}
      <LegendGroup
        key={`${title}-${baseItem[title]}`}
        groupInfo={baseItem[title]}
        groupTitle={title}
        initiallyFolded
        isVisible
      />
      {/* Section for each supporting layer */}
      { legendInfo
        ? Object.keys(legendInfo).map((groupItem) => (
          <LegendGroup
            key={groupItem}
            groupInfo={legendInfo[groupItem]}
            groupTitle={groupItem}
            initiallyFolded={false}
            isVisible={supLayersVisibility[groupItem]}
          />
        ))
        : ''}

    </div>

  );
}

Legend.propTypes = {
  legendInfo: legendInfoType,
};


export default Legend;
