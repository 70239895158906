import { combineReducers } from 'redux';
import altModelFile from './altModelFile';
import toggleableControls from './controls';
import highestZIndex from './highestZIndex';
import { caseIds, modelIds, scenIds } from './ids';
import interactions from './interactions';
import { casesById, modelsById, scensById } from './lookupById';
import map from './map';
import { offlineShowing, operationalShowing } from './mapFilter';
import nonSpatialEnts from './nonSpatialEnts';
import popupList from './popupList';
import { selectedCaseId, selectedModelId, selectedScenId } from './selectedId';
import sliderTime from './sliderTime';
import spatialEnts from './spatialEnts';
import timelineExpanded from './timelineExpanded';
import graphList from './graphList';

export default combineReducers({
  modelIds,
  modelsById,
  selectedModelId,
  scenIds,
  scensById,
  selectedScenId,
  caseIds,
  casesById,
  selectedCaseId,
  altModelFile,
  map,
  toggleableControls,
  operationalShowing,
  offlineShowing,
  sliderTime,
  highestZIndex,
  interactions,
  popupList,
  nonSpatialEnts,
  spatialEnts,
  timelineExpanded,
  graphList,
});
