const sliderTime = (state = 0, action) => {
  switch (action.type) {
    case 'SET_SLIDER_TIME':
      return action.time;
    default:
      return state;
  }
};

export default sliderTime;
