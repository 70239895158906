const spatialEnts = (state = {}, action) => {
  switch (action.type) {
    case 'SET_SPATIAL_ENT_LIST':
      return action.entList;
    default:
      return state;
  }
};

export default spatialEnts;
