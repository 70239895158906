export const operationalShowing = (state = true, action) => {
  switch (action.type) {
    case 'SHOW_OPERATIONAL':
      return action.status;
    default:
      return state;
  }
};

export const offlineShowing = (state = true, action) => {
  switch (action.type) {
    case 'SHOW_OFFLINE':
      return action.status;
    default:
      return state;
  }
};
