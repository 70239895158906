export default function modelPathNamesMatch(urlName, modelName) {
  // If pathname key not in modelInfo and url is the base route
  // we want to add to map
  if (modelName === undefined && urlName === '') {
    return true;
  }
  let matching = false;
  if (typeof modelName === 'string') {
    // case insensitive if you expect user to type it
    if (modelName.toLowerCase() === urlName.toLowerCase()) {
      matching = true;
    }
  } else if (typeof modelName === 'object') {
    modelName.forEach((name) => {
      if (name.toLowerCase() === urlName.toLowerCase()) {
        matching = true;
      }
    });
  }
  return matching;
}
