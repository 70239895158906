module.exports = {
  importFiles: () => {
    function importAll(r, cache) {
      r.keys().forEach(key => cache[key] = r(key));
    }
    const myModels = require.context('../../public', true, /model_info.(json)$/);
    let models = myModels.keys().map(myModels);
    
    const nonSpatialEntInfoDict = {};    
    importAll(require.context('../../public', true, /Non Spatial Entities.(json)$/), nonSpatialEntInfoDict)
    
    const spatialEntInfoDict = {};
    importAll(require.context('../../public', true, /[^Non ]Spatial Entities.(json)$/), spatialEntInfoDict);
  
    const legendInfoDict = {};
    importAll(require.context('../../public', true, /map_app_legend.(json)$/), legendInfoDict);
    return {models, nonSpatialEntInfoDict, spatialEntInfoDict, legendInfoDict};
  }
}
