export const modelsById = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_MODEL':
      return {
        ...state,
        [action.id]: {
          name: action.name,
          id: action.id,
          description: action.description,
          attributes: action.attributes,
          time_unit: action.time_unit,
          scenarios: action.scenarios,
          type: action.subtype,
        },
      };
    default:
      return state;
  }
};

export const scensById = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_SCEN':
      return {
        ...state,
        [action.id]: {
          name: action.name,
          id: action.id,
          description: action.description,
          cases: action.cases,
        },
      };
    default:
      return state;
  }
};

export const casesById = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_CASE':
      return {
        ...state,
        [action.id]: {
          name: action.name,
          id: action.id,
          description: action.description,
          ent_files_dir: action.ent_files_dir,
          ent_files: action.ent_files,
          sup_files_dir: action.sup_files_dir,
          sup_files: action.sup_files,
          init_st_files_dir: action.init_st_files_dir,
          init_st_files: action.init_st_files,
          max_time: action.max_time,
          visible_layers: action.visible_layers,
          disable_timeline: action.disable_timeline,
          timeline_preload_ents: action.timeline_preload_ents,
          preload_popups: action.preload_popups,
        },
      };
    default:
      return state;
  }
};
