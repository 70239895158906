import {
  Circle as CircleStyle, Stroke, Fill, Style,
} from 'ol/style';
import {
  operationalColor, offlineColor, operationalColorCustomOpacity, offlineColorCustomOpacity,
} from './colors';

// stroke and fill styles for operational feature
export const operStrokeStyle = new Stroke({
  color: operationalColor,
  width: 2,
});
export const operFillStyle = new Fill({
  color: operationalColorCustomOpacity(0.5),
});

// stroke and fill styles for offline feature
export const offStrokeStyle = new Stroke({
  color: offlineColor,
  width: 2,
});
export const offFillStyle = new Fill({
  color: offlineColorCustomOpacity(0.3),
});

// define styles by geometry type
const point = (stroke, fill) => new Style({
  image: new CircleStyle({
    radius: 5,
    stroke,
    fill,
  }),
});

const polygon = (stroke, fill) => new Style({
  stroke,
  fill,
});

const stringShape = (stroke) => new Style({
  stroke,
});

// define feature styles by type
export const styles = {
  LineString: (stroke) => stringShape(stroke),
  MultiLineString: (stroke) => stringShape(stroke),
  Point: (stroke, fill) => point(stroke, fill),
  MultiPoint: (stroke, fill) => point(stroke, fill),
  Polygon: (stroke, fill) => polygon(stroke, fill),
  MultiPolygon: (stroke, fill) => polygon(stroke, fill),
  GeometryCollection: (stroke, fill) => new Style({
    stroke,
    fill,
    image: new CircleStyle({
      radius: 5,
      stroke,
      fill,
    }),
  }),
  Circle: (stroke, fill) => new Style({
    stroke,
    fill,
  }),
};

const styleCache = {};
const hiddenStyle = new Style({});
function createStyle(feature, state) {
  const geometry = feature.getGeometry().getType();
  const objId = feature.getProperties().OBJECTID;
  const properties = feature.getProperties();
  
  const getStyle = (color, stroke, fill) => {
    let style = styleCache[geometry][color];
    if (!style) {
      if (!stroke) {
        stroke = new Stroke({
          color: `rgba(${color})`,
          width: 1,
        });
      }
      if (!fill) {
        fill = new Fill({
          color: `rgba(${color})`,
        });
      }      
      // find style for geometry with color and add to cache
      style = styleCache[geometry][color] = styles[geometry](stroke, fill);
    }
    
    return style;
  }
  
  if (!(geometry in styleCache)) {
    // add geometry to cache
    styleCache[geometry] = {};
  }
  
  if (properties.LayerGroup === 'Spatial Supporting Data' && properties.QMLColor) {
    return getStyle(properties.QMLColor);
  }
  if (objId === undefined) {
    // color shapes without an id blue
    return getStyle('0, 0, 255, 0.3');
  }
  // determine style to give this shape
  if (state === undefined) {
    return hiddenStyle; // hide feature
  }

  if (state === 1) {
    // color shape green (use state value to distinguish from stateless features with same color)
    return getStyle(1, operStrokeStyle, operFillStyle);
  }
  // state = 0
  // color shape red (use state value to distinguish from stateless features with same color)
  return getStyle(0, offStrokeStyle, offFillStyle);
}

export function immedEffectsStyleFunction(feature) {
  let state = '';
  state = feature.getProperties().EntityState;
  const style = createStyle(feature, state);
  return style;
}

export function initStateStyleFunction(feature) {
  let state = '';
  state = feature.getProperties().InitialState;
  const style = createStyle(feature, state);
  return style;
}
