import React from 'react';
import { connect } from 'react-redux';
import '../App.css';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { updateMapExtent } from '../utils/mapHelpers';


class ZoomToExtent extends React.Component {
  constructor(props) {
    super(props);
    this.zoomToExtent = this.zoomToExtent.bind(this);
  }

  componentDidMount() {
    const zoomButton = document.getElementById('zoom-button');
    zoomButton.addEventListener('click', this.zoomToExtent);
  }
  
  componentWillUnmount() {
    const zoomButton = document.getElementById('zoom-button');
    zoomButton.removeEventListener('click', this.zoomToExtent);
  }

  zoomToExtent() {
    const { map } = this.props;
    updateMapExtent(map, true);
  }

  render() {
    return (
      <>
        <div id="zoom-to-extent-div" className="ol-unselectable ol-control zoom-to-extent">
          <button
            type="button"
            id="zoom-button"
            data-testid="zoom-button"
          >
            <FontAwesomeIcon icon={faArrowsAlt} color="rgb(255,255,255,1)" />
          </button>
        </div>

      </>
    );
  }
}


ZoomToExtent.propTypes = {
  map: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  map: state.map,

});

export default connect(mapStateToProps)(ZoomToExtent);
