import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectModel, selectScenario, selectCase } from '../actions';
import ModelSelect from './ModelSelect';
import OpenModelButton from './OpenModelButton';

class ModelForm extends React.Component {
  constructor(props) {
    super(props);

    this.onModalExit = this.onModalExit.bind(this);
  }

  /*  This method changes the state back to default for model,
       scenario, and case
  */
  onModalExit() {
    const { dispatch, onHide } = this.props;
    dispatch(selectModel(-1));
    dispatch(selectScenario(-1));
    dispatch(selectCase(-1));

    onHide();
  }

  render() {
    const { show, onHide } = this.props;
    return (
      <Modal
        show={show}
        onHide={this.onModalExit}
        data-testid="modelModal"
        size="lg"
        aria-labelledby="modelModalTitle"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="modelModalTitle">
            Select Model
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label>Select a pre-installed model</Form.Label>
            <Form.Group controlId="modelSelect" style={{ width: '250px' }}>
              <ModelSelect />
            </Form.Group>
            <Form.Group controlId="modelFileInput">
              <Form.Label className="btn btn-secondary">Or select an alternative model</Form.Label>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <OpenModelButton onClick={onHide} />
        </Modal.Footer>
      </Modal>
    );
  }
}

ModelForm.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.elementType.isRequired,
  dispatch: PropTypes.elementType.isRequired,
};

export default connect()(ModelForm);
