import { addCase, addScenario, addModel } from '../actions';

export default function addNewModel(modelInfo, type, dispatch) {
  const scenarios = [];
  let cases;
  modelInfo.scenarios.forEach((s) => {
    cases = [];
    s.cases.forEach((c) => {
      const newCase = addCase({
        name: c.name,
        description: c.description,
        ent_files_dir: c.ent_files_dir,
        ent_files: c.ent_files,
        sup_files_dir: c.sup_files_dir,
        sup_files: c.sup_files,
        init_st_files_dir: c.init_st_files_dir,
        init_st_files: c.init_st_files,
        max_time: c.max_time,
        disable_timeline: c.disable_timeline,
        visible_layers: c.visible_layers,
        timeline_preload_ents: c.timeline_preload_ents,
        preload_popups: c.preload_popups,

      });
      dispatch(newCase); // add new case
      cases.push(newCase.id);
    });

    const newScen = addScenario({
      name: s.name,
      description: s.description,
      cases,
    });
    dispatch(newScen); // add new scenario
    scenarios.push(newScen.id);
  });

  const newModel = addModel({
    name: modelInfo.name,
    attributes: modelInfo.attributes,
    description: modelInfo.description,
    time_unit: modelInfo.time_unit,
    scenarios,
    subtype: type,
  });
  dispatch(newModel); // add new model
  return newModel;
}
