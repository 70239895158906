export const selectedModelId = (state = -1, action) => {
  switch (action.type) {
    case 'SELECT_MODEL':
      return action.id;
    default:
      return state;
  }
};

export const selectedScenId = (state = -1, action) => {
  switch (action.type) {
    case 'SELECT_SCEN':
      return action.id;
    default:
      return state;
  }
};

export const selectedCaseId = (state = -1, action) => {
  switch (action.type) {
    case 'SELECT_CASE':
      return action.id;
    default:
      return state;
  }
};
