import LayerGroup from 'ol/layer/Group';
import Style from 'ol/style/Style';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { showOffline, showOperational } from '../actions';
import { groupInfoType, mapType } from '../types';

import formatID from '../utils/formatID';


class LegendGroup extends React.Component {
  constructor(props) {
    super(props);
    const { initiallyFolded, groupTitle } = this.props;    
    this.state = {
      folded: initiallyFolded,
    };
    this.groupId = formatID(`group-${groupTitle}`);
    this.toggleGroupVisibility = this.toggleGroupVisibility.bind(this);
    this.hoverEntityItem = this.hoverEntityItem.bind(this);
    this.hoverSupportingItem = this.hoverSupportingItem.bind(this);
    this.unhoverItem = this.unhoverItem.bind(this);
    this.listeningDivs = [];
  }

  componentDidMount() {
    const { groupTitle, groupInfo } = this.props;
    const myButton = document.getElementById(formatID(`button-${groupTitle}`));
    if (myButton) {
      myButton.addEventListener('click', this.toggleGroupVisibility);
    }
    if (groupTitle === 'Entities') {
      Object.keys(groupInfo).forEach((title) => {
        const itemDiv = document.getElementById(formatID(`item-${groupTitle}-${title}`));
        const showState = title === 'Operational' ? 1 : 0;
        itemDiv.addEventListener('mouseenter', this.hoverEntityItem.bind(null, showState));
        itemDiv.addEventListener('mouseleave', this.unhoverItem);
        // Remember this div so we can remove listeners when unmounting
        this.listeningDivs.push(itemDiv);
      });
    } else {
      Object.keys(groupInfo).forEach((title) => {
        const itemDiv = document.getElementById(formatID(`item-${groupTitle}-${title}`));
        // Format color from title to be same as QMLColor variable
        const showState = groupInfo[title].Color.replace('rgba(', '').replace(')', '');
        itemDiv.addEventListener('mouseenter', this.hoverSupportingItem.bind(null, showState));
        itemDiv.addEventListener('mouseleave', this.unhoverItem);
        this.listeningDivs.push(itemDiv);
      });
    }
  }

  componentWillUnmount() {
    const { groupTitle } = this.props;
    const myButton = document.getElementById(formatID(`button-${groupTitle}`));
    if (myButton) {
      myButton.removeEventListener('click', this.toggleGroupVisibility);
    }
    this.listeningDivs.forEach((div) => {
      div.removeEventListener('mouseenter', this.hoverSupportingItem);
      div.removeEventListener('mouseenter', this.hoverEntityItem);
      div.removeEventListener('mouseleave', this.unhoverItem);
    });
  }

  hoverEntityItem(showState) {
    const { map, dispatch } = this.props;
    const layers = map.getLayers().getArray();
    if (showState === 1) {
      // if showing operational we don't want to show offline & vice versa
      dispatch(showOffline(false));
    } else {
      dispatch(showOperational(false));
    }
    layers.forEach((layer) => {
      if (layer instanceof LayerGroup && layer.get('title') !== 'Base Maps') {
        const vectorLayers = layer.getProperties().layers.getArray();
        vectorLayers.forEach((vectorLayer) => {
          const lyrGroup = vectorLayer.get('LayerGroup');
          const stateProperty = lyrGroup === 'Spatial Initial State' ? 'InitialState' : 'EntityState';
          if (vectorLayer.getVisible()) {
            const features = vectorLayer.getSource().getFeatures();
            features.forEach((feature) => {
              const featState = feature.get(stateProperty);
              if (featState !== showState) {
                feature.setStyle(new Style({}));
              }
            });
          }
        });
      }
    });
  }

  hoverSupportingItem(showState) {
    const { map, groupTitle, dispatch } = this.props;
    // We don't want timeline changing style of these features if running while
    // we are hovering
    dispatch(showOffline(false));
    dispatch(showOperational(false));
    const layers = map.getLayers().getArray();
    layers.forEach((layer) => {
      if (layer instanceof LayerGroup && layer.get('title') !== 'Base Maps') {
        const vectorLayers = layer.getProperties().layers.getArray();
        vectorLayers.forEach((vectorLayer) => {
          if (vectorLayer.getVisible()) {
            const features = vectorLayer.getSource().getFeatures();
            features.forEach((feature) => {
              if (vectorLayer.get('title') !== groupTitle || feature.get('QMLColor') !== showState) {
                feature.setStyle(new Style({})); // wrong layer or state, hide feature
              }
            });
          }
        });
      }
    });
  }

  unhoverItem() {
    const { map, dispatch } = this.props;
    // Let timeline change the style on these
    dispatch(showOffline(true));
    dispatch(showOperational(true));
    const layers = map.getLayers().getArray();
    layers.forEach((layer) => {
      if (layer instanceof LayerGroup && layer.get('title') !== 'Base Maps') {
        const vectorLayers = layer.getProperties().layers.getArray();
        vectorLayers.forEach((vectorLayer) => {
          // This is safe to do ONLY because we can't change what
          // layers are visible while hovering
          if (vectorLayer.getVisible()) {
            const features = vectorLayer.getSource().getFeatures();
            features.forEach((feature) => {
              // show all features again
              feature.setStyle(null);
            });
          }
        });
      }
    });
  }

  toggleGroupVisibility() {
    const { folded } = this.state;
    if (folded) {
      this.setState({ folded: false });
    } else {
      this.setState({ folded: true });
    }
  }
  
  render() {
    const { groupInfo, groupTitle, isVisible } = this.props;
    const { folded  } = this.state;
    return (
      <div className={isVisible ? "map-app-legend-group-content" : 'hide'} id={this.groupId} data-testid={this.groupId}>
        <Button
          id={formatID(`button-${groupTitle}`)}
          data-testid={formatID(`button-${groupTitle}`)}
          className={folded ? 'grouping-fold-button list-button-show' : 'grouping-fold-button'}
        />
        <div className="map-app-legend-group-title">
          {groupTitle}
        </div>
        <ul
          data-testid={formatID(`legend-list-${groupTitle}`)}
          id={formatID(`legend-list-${groupTitle}`)}
          className={folded ? 'map-app-legend-list' : 'hide'}
        >
          {
            Object.keys(groupInfo).map((title) => (
              <li key={title} className="map-app-legend-item" id={formatID(`item-${groupTitle}-${title}`)} data-testid={formatID(`item-${groupTitle}-${title}`)}>
                <div
                  className="map-app-legend-color-box"
                  data-testid={title}
                  style={{ backgroundColor: groupInfo[title].Color }}
                />
                <div className="map-app-legend-list-text">{title}</div>
              </li>
            ))
          }
        </ul>
      </div>

    );
  }
}

LegendGroup.propTypes = {
  groupTitle: PropTypes.string.isRequired,
  groupInfo: groupInfoType,
  initiallyFolded: PropTypes.bool.isRequired,
  map: mapType,
  dispatch: PropTypes.elementType.isRequired,
};

const mapStateToProps = (state) => ({
  map: state.map,
  offlineShowing: state.offlineShowing,
  operationalShowing: state.operationalShowing,
});

export default connect(mapStateToProps)(LegendGroup);
