import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav } from 'react-bootstrap';
import ToggleMapControls from './ToggleMapControls';
import ScenSelect from '../containers/ScenSelect';
import CaseSelect from '../containers/CaseSelect';
import ModelSelect from '../containers/ModelSelect';
import TimeSlider from './TimeSlider';
import HelpModal from './HelpModal';
import PreloadedItemList from './PreloadedItemList';
import '../custom.scss';

const urlPathName = window.location.pathname.replace(/^\/|\/$/g, '');
const Header = ({ mobile, disableTimeline }) => (
  <Navbar
    bg="light"
    variant="light"
    expand="xxl"
    className="navbar-expand-custom"
  >

    <Navbar.Brand
      style={{ marginRight: '10px' }}
      href="/"
      onClick={(e) => e.preventDefault()}
    >
      SIREN
    </Navbar.Brand>
    {(mobile && !disableTimeline)
      && (
        <>
          <Nav.Item>
            <TimeSlider />
          </Nav.Item>
        </>
      )}
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav fill>
        {/* This is the modal button for opening models, not being used at the moment
          but I left this here in case we wanted it back in the future
          <Nav.Item>
            <Button variant="primary"
              data-testid="openModal" onClick={buttonOnClick}
              style={{marginRight:"10px"}}>
              Open Model
            </Button>
          </Nav.Item>
          */}
        <Nav.Item>
          <ModelSelect />
        </Nav.Item>
        <Nav.Item>
          <ScenSelect />
        </Nav.Item>
        <Nav.Item>
          <CaseSelect />
        </Nav.Item>
        {(!mobile && !disableTimeline)
        && (
          <>
            <Nav.Item>
              <TimeSlider />
            </Nav.Item>
          </>
        )}
        {urlPathName === 'test'
          ? (
            <Nav.Item id="nav-preloaded-item-list">
              <PreloadedItemList />
            </Nav.Item>
          )
          : ''}
        <Nav.Item id="nav-feature-filter">
        </Nav.Item>
        <Nav.Item id="nav-toggle-controls">
          <ToggleMapControls />
        </Nav.Item>
        <Nav.Item id="nav-help-modal">
          <HelpModal />
        </Nav.Item>
      </Nav>
    </Navbar.Collapse>

  </Navbar>
);


Header.propTypes = {
  mobile: PropTypes.bool,
  disableTimeline: PropTypes.bool,
};

export default Header;
