import { operationalColor, offlineColor } from './colors';

export default function getFeatureStateAsColour(feature, time) {
  if ((feature.get('LayerGroup') === 'Spatial Initial State')
      || (feature.get('LayerGroup') === 'Non Spatial Initial State')) {
    if (feature.get('InitialState') === 0) {
      return offlineColor;
    }
    if (feature.get('InitialState') === 1) {
      return operationalColor;
    }
  } else if ((feature.get('LayerGroup') === 'Spatial Entity')
            || (feature.get('LayerGroup') === 'Non Spatial Entity')) {
    if ('ChangeTime' in feature.getProperties()) {
      if (feature.get('ChangeTime') <= time) {
        return operationalColor;
      }
      if (feature.get('ChangeTime') > time) {
        return offlineColor;
      }
    } else {
      if (feature.get('EntityState') === 1) {
        return operationalColor;
      }
      if (feature.get('EntityState') === 0) {
        return offlineColor;
      }
    }
  }
  return 'black';
}
